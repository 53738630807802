@import "../../../stylesheets/global.scss";

.section__hero {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100vh;
    padding-top: 93px;
    max-height: 650px;
    // background: rgba(125, 129, 148, 0.5);
    background: #f4f6ff;
    z-index: 0;

    @include media-breakpoint-down(md) {
        height: auto;
        max-height: none;
    }

    &--background {
        position: absolute;
        top: 93px;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        z-index: 0;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        .hero__images {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 25%;
            max-width: 500px;
            height: 50vh;
            background-repeat: no-repeat;
            background-size: contain;

            &.left {
                background-image: url("./images/left-side.svg");
                background-position: left;
            }
            &.right {
                background-image: url("./images/right-side.svg");
                background-position: right;
            }
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 633px;
        z-index: 10;

        .hero__content {
            &--text {
                text-align: center;
                padding: 0px 1.5em;

                h1 {
                    margin-bottom: 15px;
                }

                p {
                    color: rgba(6, 17, 33, 0.7);
                    padding: 0 2rem;
                    white-space: break-spaces;
                    @include media-breakpoint-down(md) {
                        padding: 0;
                    }
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }

            &--search {
                position: relative;
                display: flex;
                padding-top: 28px;
                width: 100%;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    padding-bottom: 33px;

                    .component__searchbar {
                        margin-top: -50px; // raise to hide part of the image
                    }
                }

                span.hero__search--img {
                    display: none;
                    background: url("./images/mobile-search.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 250px;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }

                    @include media-breakpoint-down(sm) {
                        height: 215px;
                    }
                }
            }
        }
    }
}
