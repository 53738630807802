@import "../../stylesheets/global.scss";

.page-coupon {
    padding-top: 40px;

    .page-coupon__content {
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;
        min-height: 100vh;

        &--title {
            text-align: center;
            padding-bottom: 32px;

            p.subtitle {
                color: #061121;
                mix-blend-mode: normal;
                opacity: 0.8;
                padding: 0 25%;

                @include media-breakpoint-down(md) {
                    padding: 12px 1.5em;
                }
            }
        }

        &--rxcard {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 65px;

            .component__rxcard--card {
                max-width: 416px;

                @include media-breakpoint-down(lg) {
                    max-width: 100%;
                }
            }
        }

        &--drug-desc {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .drug-description__content {
                flex: 2;
                padding-right: 25px;

                &--item {
                    padding-bottom: 15px;

                    p {
                        color: rgba(6, 17, 33, 0.5);
                        padding: 10px 25px 0 0;
                    }
                }
            }

            .drug-description__image {
                flex: 1;

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }

        &--benefits {
            width: 100%;
            padding-top: 50px;

            h2 {
                text-align: center;
            }

            .content__benefits {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-top: 50px;

                @include media-breakpoint-down(lg) {
                    padding-top: 25px;
                }

                .component__benefits {
                    width: 70%;
                    flex-wrap: wrap;
                    align-items: baseline;

                    .benefit__item {
                        flex: initial;
                        max-width: 50%;
                        margin-bottom: 25px;
                        padding: 0;
                    }
                }
            }
        }

        &--savings {
            padding-top: 50px;
            .content__savings {
                &--item {
                    padding-bottom: 25px;
                    padding-right: 10%;

                    &:nth-child(2n) {
                        padding-right: 25%;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding-right: 0;
                        &:nth-child(2n) {
                            padding-right: 0;
                        }
                    }

                    h2 {
                        font-size: 28px;
                        padding-bottom: 5px;
                    }
                }
            }
        }

        &--faq {
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                text-align: center;
            }

            .content__faq {
                padding-top: 25px;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }

        &--download {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding: 30px 0;

            .download-card-action {
                min-width: 25%;

                .button {
                    width: 100%;
                }
            }
        }
    }
}
