@import "../../../stylesheets/global.scss";

.section__partners {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;

    h2 {
        text-align: center;
    }

    &--wrap {
        padding: 50px 0;

        @include media-breakpoint-down(lg) {
            padding: 25px 0;
        }
    }

    &--link {
        .button {
            font-weight: normal;
            padding: 8px 28px;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-bottom: 50px;
    }
}
