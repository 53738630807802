@import "../../stylesheets/global.scss";

.component-logo {
    z-index: 1;
}

.component-logo img {
    display: inline-block;
    width: auto;
    height: 40px;
    outline: none;
    pointer-events: none;
    user-select: none;
    aspect-ratio: 141/53;

    @include media-breakpoint-up(md) {
        height: 53px;
    }
}
