@import "../../stylesheets/global.scss";
@import "../../stylesheets/animation.scss";

$ball_size: 10px;

.component__spinner {
    position: absolute;
    display: flex;
    top: calc($ball_size / 2);
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
}

.component__spinner--balls {
    @include loading-ball-pulse();

    > div {
        animation-fill-mode: both;

        width: $ball_size;
        height: $ball_size;
        border-radius: 100%;
        margin: calc($ball_size / 2);
        background-color: $main-blue;

        display: inline-block;
    }
}
