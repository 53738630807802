@import "../../stylesheets/global.scss";

.modal.component__modal-window {
    flex-shrink: initial;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    display: block;
    z-index: 9999;

    .modal-fullscreen .modal-content {
        padding: 24px 16px;

        .modal-body {
            padding: 0;
        }
    }

    .modal-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .block {
            display: flex;
            min-width: 20px;
            justify-content: center;
        }
        h3 {
            font-size: 24px;
            line-height: 125%;
            color: $main-blue;
        }

        span.close-icon {
            position: relative;
            display: block;
            background-image: url("./images/close-icon.svg");
            background-repeat: no-repeat;
            width: 14px;
            height: 14px;
        }
    }
}

.component__modal-small-window {
    display: flex;
    justify-content: center;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
    animation: show 0.5s ease;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 99;

    .modal-small__wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-small__body {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background: #fff;
        border-radius: 6px;
        width: 30%;
        min-height: 386px;
        padding: 29px;
        overflow: hidden;

        &.success {
            display: flex;
            .modal-small__successs {
                opacity: 1;
                visibility: visible;
            }
        }

        @include media-breakpoint-down(md) {
            width: 90%;
            padding: 25px 10px;
        }

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 83%;
            color: #061121;

            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
        }
    }

    .modal-small__title {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;

        .block {
            display: flex;
            min-width: 20px;
        }

        span.close-icon {
            position: relative;
            display: block;
            cursor: pointer;

            transition: all 0.25s;

            &:hover {
                transform: scale(1.1);
            }
            &::before {
                content: "";

                position: absolute;

                width: 18px;
                height: 2px;
                background-color: $black;

                transform: rotate(45deg);
            }

            &::after {
                content: "";

                position: absolute;
                left: 0px;
                width: 18px;
                height: 2px;
                background-color: $black;

                transform: rotate(-45deg);
            }
        }
    }

    .modal-small__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    .modal-small__bottom {
        display: flex;
        justify-content: center;
        width: 100%;
        .button {
            width: 100%;
        }
    }
}

.modal-small__successs {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;

    transition: all 0.5s;

    &--wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
        padding: 0 16px;
    }

    .img-man {
        width: 50%;
    }

    .msg {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 146%;
        color: rgba(6, 17, 33, 0.7);
        text-align: center;
    }

    .button {
        width: 100%;
        max-width: 250px;
    }
}

@keyframes modal-success-animation {
    from {
        right: 0;
        left: 0;
    }
    to {
        opacity: 1;
    }
}
