@import "../../stylesheets/global.scss";

.page-application {
    @include media-breakpoint-down(lg) {
        overflow-x: hidden;
    }
    .page-application__hero {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // background: rgba(244, 246, 255, 0.5);
        background: #f4f6ff;
        height: 536px;
        padding: 40px 0;

        &::after {
            content: "";
            display: flex;
            position: absolute;
            background: url("./images/application-hero.png");
            bottom: -220px;
            left: 0;
            right: 0;
            width: 545px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 99;
            margin: 0 auto;
        }

        @include media-breakpoint-down(sm) {
            &::after {
                background: url("./images/application-hero-mobile.png");
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
            }
        }

        @include media-breakpoint-only(md) {
            &::after {
                bottom: -40%;
            }
        }

        @include media-breakpoint-between(md, lg) {
            &::after {
                bottom: -50%;
            }
        }
    }

    .application-hero__title {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-weight: normal;
            font-size: 32px;
            line-height: 40px;
            padding: 0 15%;

            @include media-breakpoint-down(md) {
                padding: 0 5%;
                font-size: 24px;
                line-height: 30px;
            }
        }

        .subtitle {
            padding: 16px 20%;
            color: rgba(6, 17, 33, 0.7);

            @include media-breakpoint-down(md) {
                padding: 10px 15%;
            }
        }
    }

    .page-application__downloadbtn {
        padding-top: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 999;

        @include media-breakpoint-down(md) {
            padding-top: 100px;
        }
    }

    .page-application__highlights {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 50px;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        .app-highlight__item {
            height: 100%;
            max-width: 29%;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 0 15px;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(lg) {
                padding: 0;
                margin-bottom: 15px;
                max-width: 100%;
            }

            .img {
                width: 15%;
            }

            &--content {
                padding: 20px 0;
                text-align: center;

                @include media-breakpoint-down(lg) {
                    padding: 15px 5%;
                }

                h3 {
                    font-family: $font-family-prompt;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 125%;
                    color: #061121;
                    padding-bottom: 12px;
                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: #6f757d;
                    mix-blend-mode: normal;
                    opacity: 0.8;
                }
            }
        }
    }

    .page-application__reviews {
        padding-top: 50px;

        @include media-breakpoint-down(md) {
            padding-top: 25px;
        }

        .app-reviews__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-bottom: 40px;

            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }

            .subtitle {
                font-weight: normal;
                font-size: 18px;
                line-height: 156%;
                color: #6f757d;

                padding: 12px 20%;

                @include media-breakpoint-down(md) {
                    padding: 6px 5%;
                }
            }
        }
      
        .app-reviews__content {
            display: flex;
            padding-top: 40px;
            padding-bottom: 100px;

            @include media-breakpoint-down(lg) {
                padding-bottom: 50px;
            }

            .embla__container {
                display: flex;
                gap: 15px;
            }
    
            .review-item {
                display: flex;
                flex: 0 0 33.3%;

                &:last-child {
                    margin-right: 12.5px;
                }
            }

            .app-highlight__item {
                &--wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    border: 1px solid rgba(43, 48, 122, 0.2);
                    border-radius: 6px;
                    padding: 24px;
                }

                &--header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;

                    .date {
                        color: #b4b7bc;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    .reviewer-name {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 24px;
                        color: #6f757d;
                    }

                    .rate {
                        padding-top: 5px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        .star {
                            display: flex;
                            margin: 0 2px;
                            &::before {
                                content: "\2605";
                                color: #fdc219;
                            }
                        }
                    }
                }

                &--content {
                    padding-top: 13px;
                    text-align: center;

                    p {
                        font-size: 16px;
                        line-height: 150%;
                        color: #061121;
                    }
                }
            }
        }
    }

    .page-application__download-app-now {
        background-color: #f3f6fb;
        padding: 38px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
            h2 {
                font-size: 22px;
                font-weight: normal;
            }
        }

        .component__app-download-btns {
            margin-top: 24px;
            margin-bottom: 38px;
        }

        .component__app-download-link img {
            height: 55px;

            &:last-child {
                height: 54px;
            }
        }

        .download-app__form {
            display: flex;
            width: 50%;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                width: 90%;
            }

            .or {
                text-align: center;
                position: relative;
                width: 100%;
                color: #2b307a;
                font-size: 18px;
                font-weight: 500;

                &::before,
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: calc(50% - 1px);
                    bottom: 0;
                    width: calc(50% - 20px);
                    height: 1px;
                    background-color: #2b307a;
                    opacity: 0.2;
                }

                &::before {
                    left: 0;
                }
                &::after {
                    right: 0;
                }
            }

            &--content {
                padding-top: 16px;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;

                .notice {
                    padding-bottom: 12px;
                    font-size: 16px;
                    line-height: 150%;
                    color: #061121;
                }
            }
        }
    }
}
