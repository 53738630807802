@import "../../../stylesheets/global.scss";

.component__press-article-item {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    border: 1px solid #ebecee;
    box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
    border-radius: 10px;

    flex: 0 0 33.33%;
    margin-right: 15px;
    margin-left: 15px;

    .thumb {
        border-radius: 10px;
        max-height: 210px;
        overflow: hidden;
        text-align: center;

        .react-aspect-ratio-placeholder,
        .react-aspect-ratio-placeholder img {
            border-top-right-radius: inherit;
            border-top-left-radius: inherit;
        }
    }

    .wrap {
        padding: 26px 24px;
    }

    .content {
        padding-bottom: 25px;
        h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 5px;
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 171%;
            color: #828890;
        }
    }

    .footer {
        position: absolute;
        bottom: 15px;
        left: 24px;
        right: 24px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .footer__info {
        display: flex;
        flex-direction: inherit;
        align-items: flex-end;
        padding-top: 15px;
    }

    .footer__info-press-logo {
        max-width: 50px;

        img {
            vertical-align: initial;
        }
    }

    .footer__info-date {
        display: inline;
        margin-left: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 171%;
        color: #828890;

        &::before {
            content: "";
            display: inline-block;
            background-image: url("../images/calendar.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px;
            vertical-align: text-top;
            margin-right: 4px;
        }
    }

    .footer__link {
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 171%;

            &::after {
                content: "";
                display: inline-block;
                background-image: url("../images/link-blank.svg");
                background-repeat: no-repeat;
                background-size: cover;
                width: 14px;
                height: 14px;
                margin-left: 4px;
                opacity: 1;
                position: relative;
            }
        }
    }
}
