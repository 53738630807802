// Fonts
$font-family-lato: "Lato", sans-serif;
$font-family-prompt: "Prompt", sans-serif;

// Colors

$white: #ffffff;
$black: #061121;
$blue: #4683ef;
$green: #2fbf61;
$navy-blue: #214585;
$light-blue: #f7f9ff;
$grey: #d7d7d7;
$purple: #921fa8;
$light-purple: #be4bd4;
$dark-purple: #7d18a4;

// NEW COLORS
$main-blue: #2b307a;

// Font weight

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 900;

$transition: 0.5s ease;

// Gradients

$main-gradient: linear-gradient(180deg, $dark-purple 0%, $light-purple 81.2%);

// // Breakpoints
// $mb: 320px;
// $tb: 768px;
// $dt: 992px;

// Layers

$menu: 100;
$modal: 200;
$overlay: 300;

// custom
$sample_saving_grid_size: 1.7fr 1.8fr 1fr 1.5fr;
