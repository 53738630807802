@import "../../stylesheets/global.scss";

.page-search-main-wrapper {
    position: relative;
}

.page-search {
    .page-search__description {
        min-height: 338px;
        box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
    }

    .search-result__main--settings {
        display: flex;
        flex-direction: row;
        z-index: 9;
    }

    .search__result-location {
        display: flex;
        flex: 1;
        max-width: 250px;

        @include media-breakpoint-between(md, xl) {
            max-width: 150px;
        }
    }

    .search__result-sorts {
        display: flex;
        flex: 1;
        padding-left: 18px;
        align-items: center;
    }
}

.page-search-not-found {
    position: relative;
    display: flex;
    padding: 40px 0 120px;

    .search-not-found__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--img {
            display: flex;
            width: 100%;
            max-width: 300px;
        }

        &--text {
            text-align: center;
            padding: 25px 0 30px;
        }
    }

    .search-not-found__search-bar {
        display: flex;
        align-items: center;
        justify-content: center;

        .component__searchbar {
            max-width: 55%;

            @include media-breakpoint-down(lg) {
                max-width: 90%;
            }
        }
    }
}
