.button {
    display: inline-block;

    border-radius: 6px;
    border-style: solid;
    border-width: 1px;

    font-family: $font-family-lato;
    font-size: 16px;
    line-height: 150%;
    font-weight: normal;
    white-space: nowrap;
    text-align: center;
    vertical-align: top;

    padding: 8px 30px;

    transition: background-color $transition, border-color $transition,
        color $transition, opacity $transition;

    cursor: pointer;

    @include media-breakpoint-up(lg) {
        padding-top: 0.7em;
        padding-bottom: 0.7em;
    }

    &:focus {
        outline: none;
    }

    @include media-breakpoint-down(md) {
        padding: 0.7rem 2.5rem;
    }
}

.button.purple {
    background-color: $purple;
    color: #fff;
    border: none;

    &:hover {
        background-color: $light-purple;
    }
}

.button.get-a-card-btn {
    background-color: $purple;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: normal;

    &:hover {
        background-color: $light-purple;
    }
}

.button.blue {
    background: $main-blue;
    border: 1px solid $main-blue;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;

    &:hover {
        background: #3363b8;
        border: 1px solid #3363b8;
    }
}
