/* lato-300 - latin */
// @font-face {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 300;
//     src: url('./Lato/lato-v23-latin-300.eot');
//     src: local(''),
//          url('./Lato/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'),
//          url('./Lato/lato-v23-latin-300.woff2') format('woff2'),
//          url('./Lato/lato-v23-latin-300.woff') format('woff'),
//          url('./Lato/lato-v23-latin-300.ttf') format('truetype'),
//          url('./Lato/lato-v23-latin-300.svg#Lato') format('svg');
//   }

/* lato-regular - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("./Lato/lato-v23-latin-regular.eot");
    src: local(""),
        url("./Lato/lato-v23-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        url("./Lato/lato-v23-latin-regular.woff2") format("woff2"),
        url("./Lato/lato-v23-latin-regular.woff") format("woff"),
        url("./Lato/lato-v23-latin-regular.ttf") format("truetype"),
        url("./Lato/lato-v23-latin-regular.svg#Lato") format("svg");
    font-display: swap;
}
/* lato-700 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("./Lato/lato-v23-latin-700.eot");
    src: local(""),
        url("./Lato/lato-v23-latin-700.eot?#iefix") format("embedded-opentype"),
        url("./Lato/lato-v23-latin-700.woff2") format("woff2"),
        url("./Lato/lato-v23-latin-700.woff") format("woff"),
        url("./Lato/lato-v23-latin-700.ttf") format("truetype"),
        url("./Lato/lato-v23-latin-700.svg#Lato") format("svg");
    font-display: swap;
}
/* lato-900 - latin */
// @font-face {
// 	font-family: 'Lato';
// 	font-style: normal;
// 	font-weight: 900;
// 	src: url('./Lato/lato-v23-latin-900.eot');
// 	src: local(''),
// 		url('./Lato/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'),
// 		url('./Lato/lato-v23-latin-900.woff2') format('woff2'),
// 		url('./Lato/lato-v23-latin-900.woff') format('woff'),
// 		url('./Lato/lato-v23-latin-900.ttf') format('truetype'),
// 		url('./Lato/lato-v23-latin-900.svg#Lato') format('svg');
// 	font-display: swap;
// }

/* latin */
// @font-face {
// 	font-family: 'Prompt';
// 	font-style: normal;
// 	font-weight: 300;
// 	src: url('./Prompt/prompt-v10-latin-300.eot');
// 	src: local(''),
// 		url('./Prompt/prompt-v10-latin-300.eot?#iefix')
// 			format('embedded-opentype'),
// 		url('./Prompt/prompt-v10-latin-300.woff2') format('woff2'),
// 		url('./Prompt/prompt-v10-latin-300.woff') format('woff'),
// 		url('./Prompt/prompt-v10-latin-300.ttf') format('truetype'),
// 		url('./Prompt/prompt-v10-latin-300.svg#Prompt') format('svg');
// 	font-display: swap;
// }

/* latin */
@font-face {
    font-family: "Prompt";
    font-style: normal;
    font-weight: 400;
    src: url("./Prompt/prompt-v10-latin-regular.eot");
    src: local(""),
        url("./Prompt/prompt-v10-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        url("./Prompt/prompt-v10-latin-regular.woff2") format("woff2"),
        url("./Prompt/prompt-v10-latin-regular.woff") format("woff"),
        url("./Prompt/prompt-v10-latin-regular.ttf") format("truetype"),
        url("./Prompt/prompt-v10-latin-regular.svg#Prompt") format("svg");
    font-display: swap;
}

/* latin */
@font-face {
    font-family: "Prompt";
    font-style: normal;
    font-weight: 500;
    src: url("./Prompt/prompt-v10-latin-500.eot");
    src: local(""),
        url("./Prompt/prompt-v10-latin-500.eot?#iefix")
            format("embedded-opentype"),
        url("./Prompt/prompt-v10-latin-500.woff2") format("woff2"),
        url("./Prompt/prompt-v10-latin-500.woff") format("woff"),
        url("./Prompt/prompt-v10-latin-500.ttf") format("truetype"),
        url("./Prompt/prompt-v10-latin-500.svg#Prompt") format("svg");
    font-display: swap;
}

/* latin */
@font-face {
    font-family: "Prompt";
    font-style: normal;
    font-weight: 600;
    src: url("./Prompt/prompt-v10-latin-600.eot");
    src: local(""),
        url("./Prompt/prompt-v10-latin-600.eot?#iefix")
            format("embedded-opentype"),
        url("./Prompt/prompt-v10-latin-600.woff2") format("woff2"),
        url("./Prompt/prompt-v10-latin-600.woff") format("woff"),
        url("./Prompt/prompt-v10-latin-600.ttf") format("truetype"),
        url("./Prompt/prompt-v10-latin-600.svg#Prompt") format("svg");
    font-display: swap;
}

/* latin */
// @font-face {
// 	font-family: 'Prompt';
// 	font-style: normal;
// 	font-weight: 700;
// 	src: url('./Prompt/prompt-v10-latin-700.eot');
// 	src: local(''),
// 		url('./Prompt/prompt-v10-latin-700.eot?#iefix')
// 			format('embedded-opentype'),
// 		url('./Prompt/prompt-v10-latin-700.woff2') format('woff2'),
// 		url('./Prompt/prompt-v10-latin-700.woff') format('woff'),
// 		url('./Prompt/prompt-v10-latin-700.ttf') format('truetype'),
// 		url('./Prompt/prompt-v10-latin-700.svg#Prompt') format('svg');
// 	font-display: swap;
// }
