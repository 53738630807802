@import "../../stylesheets/global.scss";

.page-press {
    padding-top: 40px;
    // padding-bottom: 80px;

    .title {
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .subtitle {
        padding-top: 16px;
        width: 60%;
        text-align: center;
        mix-blend-mode: normal;
        opacity: 0.6;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .press_logos-block {
        padding: 30px 41px;
        margin-top: 50px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
        border-radius: 10px;
    }

    .press__about {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding-top: 80px;
        padding-bottom: 25px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            text-align: center;
        }
    }

    .press__about-image {
        display: flex;
        flex: 1;
        padding-right: 15px;
        justify-content: flex-end;

        @include media-breakpoint-down(md) {
            margin-top: 25px;
            justify-content: center;
            padding-right: 0;
        }

        img {
            max-width: 80%;

            @include media-breakpoint-down(md) {
                max-width: 50%;
            }
        }
    }

    .press__about-content {
        flex: 1;
        h2 {
            padding-bottom: 15px;
        }

        p {
            color: #828890;
            padding-bottom: 10px;
        }
    }

    .press__last-articles {
        padding: 50px 0;
    }

    .press__last-articles-title {
        text-align: center;
    }

    .last__articles {
        padding: 25px 0 50px;
    }

    .last__articles-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    .page__info {
        text-align: center;

        h2 {
            font-weight: 500;
            font-size: 24px;
            line-height: 83%;
            color: #2b307a;
        }
    }

    .info__list {
        padding-top: 16px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: initial;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            text-align: center;
        }
    }

    .info__list-item {
        display: flex;
        flex-direction: column;
        width: 266px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgb(33 69 133 / 5%);
        border-radius: 6px;
        padding: 16px 32px;
        margin-right: 8px;

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }
    }

    .list__item-title {
        font-size: 16px;
        line-height: 150%;
        color: rgba(6, 17, 33, 0.7);
        mix-blend-mode: normal;
    }

    .list__item-value {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: #2b307a;
        mix-blend-mode: normal;
    }

    .press-kit {
        display: flex;
        background: url("./images/press-kit-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 48px 0;
        margin-top: 50px;
    }

    .press-kit__title,
    .press-kit__btn {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .press-kit__title {
        padding-bottom: 24px;

        h2 {
            padding-bottom: 8px;
        }

        p {
            color: #4d5664;
        }
    }
}
