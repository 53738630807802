.information-page {
    padding: 40px 0;

    .information-page__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 25px;
    }

    .information-page__desc {
        padding-bottom: 1em;
        text-align: justify;
    }
}
