@import "../../stylesheets/global.scss";

.component__highlights {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .highlights__item {
        display: flex;
        flex-direction: column;
        width: 32%;
        height: 375px;
        margin-bottom: 40px;
        justify-content: flex-start;

        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
        border-radius: 6px;
        padding: 35px 24px 0px 24px;

        @include media-breakpoint-between(md, xl) {
            height: auto;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            box-shadow: none;
            height: auto;
            align-items: center;
            text-align: center;
        }

        &--img {
            width: 15%;
            @include media-breakpoint-down(md) {
                width: 20% !important;
            }
        }

        &--content {
            padding-top: 20px;
            h3 {
                font-weight: bold;
                font-size: 18px;
                line-height: 156%;
                color: #061121;
            }

            p {
                padding-top: 8px;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: rgba(6, 17, 33, 0.7);
            }
        }
    }
}
