@import "../../stylesheets/global.scss";

.component__benefits {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    @include media-breakpoint-between(md, xl) {
        flex-wrap: wrap;
    }

    .benefit__item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;

        @include media-breakpoint-down(xl) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-between(md, xl) {
            width: 50%;
        }

        &:nth-child(3) {
            .info__item--img {
                margin-bottom: 6px;
            }
        }
        &:nth-child(3) {
            .info__item--img {
                height: 56px;
            }
        }

        &--content {
            margin-top: 20px;
            text-align: center;

            h3 {
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: #061121;
                padding-bottom: 6px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                color: rgba(6, 17, 33, 0.8);
                padding: 0 2em;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
        }
    }
}
