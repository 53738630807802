@import "../../stylesheets/global.scss";

.component__location-dropdown {
    position: relative;
    display: block;
    width: 100%;

    &--current {
        position: relative;
        background: #ffffff;
        border: 1px solid rgba(156, 161, 166, 0.25);
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
            border: 1px solid rgba(156, 161, 166, 0.5);
        }

        span.value {
            display: block;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #061121;

            padding: 8px 22px 8px 44px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &::before {
            content: "";
            position: absolute;
            top: 12px;
            left: 16px;
            z-index: 1;
            width: 12px;
            height: 17px;
            background: url("./images/location.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }

        .close-open__icon {
            position: absolute;
            top: 20px;
            right: 25px;

            &::before {
                content: "";

                position: absolute;

                width: 10px;
                height: 2px;
                background-color: $black;

                transform: rotate(45deg);

                transition: 0.1s;
            }

            &::after {
                content: "";

                position: absolute;
                left: 6px;
                width: 10px;
                height: 2px;
                background-color: $black;

                transform: rotate(-45deg);
                transition: 0.1s;
            }

            &.is-active {
                &::before {
                    width: 14px;
                }

                &::after {
                    width: 14px;
                    left: 0;
                }
            }
        }
    }

    &--menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 110%;

        left: -10000px;

        box-sizing: border-box;
        width: 100%;
        max-height: 0;
        padding: 20px 30px;
        overflow: hidden;

        background-color: #fff;
        border-bottom-right-radius: 13px;
        border-bottom-left-radius: 13px;
        box-shadow: 0 8px 40px 0 rgba(0, 36, 216, 0.1);
        opacity: 0;

        transition: max-height 0.5s linear;

        z-index: 1;

        @include media-breakpoint-between(md, xl) {
            width: 200%;
        }

        &.open {
            max-height: 1000px;
            left: 0;
            right: 0;
            opacity: 1;
            z-index: 999;
        }
    }

    .location--dropdown__address {
        display: flex;
        flex-direction: column;

        span {
            font-weight: bold;
            font-size: 14px;
            line-height: 143%;
            color: #061121;
            padding-bottom: 13px;
        }

        &--input {
            font-size: 16px;
            line-height: 24px;
            background: #ffffff;
            border: 1px solid #d5d6e4;
            box-sizing: border-box;
            border-radius: 6px;
            color: #061121;
            padding: 8px 16px;
        }
    }

    .save-address-btn {
        display: flex;
        align-self: flex-end;
        margin-top: 20px;
    }
}

.dropdown__title {
    padding: 8px 16px;

    font-family: $font-family-lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #061121;
    cursor: pointer;
    opacity: 0.6;

    &--active {
        opacity: 1;
    }

    &:hover {
        opacity: 0.8;
    }
}

.dropdown__list-item {
    padding: 11px 48px 11px 20px;
    font-weight: 700;
    font-size: 12px;

    cursor: pointer;
    opacity: 0.6;

    &:hover {
        opacity: 0.8;
    }
}

.dropdown__list-item--active {
    opacity: 1;
}

.dropdown__list-item:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.dropdown__toggle {
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #d5d6e4;
    opacity: 1;
}
