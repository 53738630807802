@import "../../stylesheets/global.scss";

.component__pharmacy-logo {
    display: block;

    & > img {
        max-height: 30px;
        max-width: 100%;
        height: auto;

        &.logo-publix,
        &.logo-rite_aid,
        &.logo-kroger {
            max-height: 50px;
        }

        @include media-breakpoint-down(sm) {
            max-height: 50px;
        }
        @include media-breakpoint-down(xs) {
            max-height: 30px;
        }
    }

    &.not-found {
        filter: grayscale(1.1);
    }
}
