@import "../../../stylesheets/global.scss";

.section__press-partners {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 125px;

    @include media-breakpoint-down(md) {
        padding-bottom: 50px;
    }

    .pharmacies {
        margin-bottom: 80px;

        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
    }

    .embla {
        position: relative;
        overflow: hidden;
        padding: 0 50px;
        z-index: 10;

        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 20px;
            height: 100%;
            z-index: 999;
            opacity: 0.3;
        }

        &::before {
            left: -30px;
            box-shadow: 10px 0px 10px 15px #b3c2d5;
        }

        &::after {
            right: -30px;
            box-shadow: -10px 0px 10px 15px #b3c2d5;
        }

        @include media-breakpoint-down(md) {
            &::after,
            &::before {
                display: none;
            }
        }
    }

    .embla__container {
        display: flex;
        user-select: none;

        @include media-breakpoint-down(md) {
            flex-direction: row;
            margin-left: calc(5px * -1);
        }
    }

    .item {
        display: flex;
        flex: 0 0 25%;
        margin-right: 15px;
        margin-left: 15px;
        flex-direction: column;

        background: #ffffff;
        border: 1px solid #ebecee;
        box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
        border-radius: 10px;
        padding: 20px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            margin: 5px;
            // padding-left: 5px;
            padding: 5px;
        }
    }

    .item__press-page {
        @extend .item;
        border: 2px solid $purple;

        a {
            height: 100%;
        }

        // @include media-breakpoint-down(md) {
        //     display: none;
        // }
    }

    .item__wrap {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:hover {
            &::before {
                animation-name: bounce;
            }
        }

        // &:before {
        //     content: "";
        //     display: flex;
        //     background: url("../../../assets/images/icons/arrow-next-page.svg");
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     width: 85px;
        //     height: 85px;
        //     animation-duration: 700ms;
        //     animation-timing-function: linear;
        //     animation-iteration-count: infinite;
        // }

        span {
            margin-top: 15px;
            font-family: $font-family-lato;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 133%;

            color: $black;
        }
    }

    .item__logo {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .item__logo-wrap {
        display: flex;
        max-width: 50%;
        text-align: center;

        [class~="press-img-logo__sfexaminer"] {
            width: 80%;
        }
        [class~="press-img-logo__cnbc"],
        [class~="press-img-logo__abc15"] {
            width: 70%;
        }
        [class~="press-img-logo__hill"] {
            width: 85%;
        }

        @include media-breakpoint-down(md) {
            max-width: 35%;
        }
    }

    .item__title {
        border-top: 1px solid #ebecee;
        padding: 20px 35px;

        @include media-breakpoint-down(md) {
            padding: 15px 25px;
        }

        h3 {
            font-family: $font-family-lato;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 133%;

            color: $black;

            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }
    }

    @keyframes bounce {
        0%,
        100% {
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        50% {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }

    .section__btn {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }
}
