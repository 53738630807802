@import "../../stylesheets/global.scss";

.page-wl-promo {
    padding: 25px 0 0;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    .wl_hero-section {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 50px * 2);
        margin: 0 50px;
        padding: 50px 0 100px;
        box-shadow: 0px 2px 4px 0px rgba(33, 69, 133, 0.4);
        border-radius: 20px;


        @include media-breakpoint-down(lg) {
            width: 100%;
            border-radius: 0;
            margin: 0;
            margin-top: -5px;
            padding: 50px 20px 0 20px;
            box-shadow: none;
        }


        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("./images/background.png");
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
            border-radius: 20px;

            @include media-breakpoint-down(lg) {
                background-image: url("./images//background-mobile.png");
                border-radius: 0;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            h1 {
                margin-bottom: 15px;
            }

            p.subtitle {
                color: rgba(6, 17, 33, 0.7);
            }
        }

        &__cards {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 100px;
            padding: 100px 0;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                gap: 20px;
                padding: 25px 0;
            }
        }
    }

    .wl_info-section {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 100px 0;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            margin: 25px 0 100px;
        }

        .wl_info-animation {
            flex: 1;
            @include media-breakpoint-down(lg) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .wl_info-video {
            width: 500px;
            @include media-breakpoint-down(md) {
                width: 90%;
            }
        }

        .wl_info-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            padding: 0 6em;
            gap: 50px;

            @include media-breakpoint-down(lg) {
                padding: 0;
                text-align: center;
                gap: 30px;

                h2 {
                    padding: 0 2em;
                }
            }
        }
    }

    .wl_benefits-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .wl_benefits-title {
            width: 60%;
            text-align: center;
            margin-bottom: 80px;

            h2 {
                padding: 0 15% 25px 15%;
            }

            @include media-breakpoint-down(lg) {
                width: 100%;
                margin-bottom: 50px;

                h2 {
                    padding: 0 0 30px;
                }

                p {
                    padding: 0 10px;
                }
            }
        }

        .wl_benefits-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 20px;
            width: 100%;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        .benefit-item {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: calc(50% - 20px);
            border-radius: 10px;
            background: #ffffff;
            box-shadow: 0px 2px 10px 0px rgba(33, 69, 133, 0.2);
            padding: 32px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &_icon {
                img {
                    max-height: 40px;
                }
            }
            &_title {
                color: #061121;
                h3 {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px; /* 155.556% */
                }
            }
            &_content {
                color: #515864;
                p {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 171.429% */
                }
            }
        }
    }

    .wl_faq-section {
        display: flex;
        width: calc(100% - 100px);
        flex-direction: column;
        gap: 70px;
        margin: 100px 50px 0 50px;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin: 100px 0 25px;
            gap: 20px;
        }

        .wl_faq-title {
            text-align: center;
        }

        .wl_faq-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // column-count: 2;
            gap: 20px;
            width: 100%;

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                grid-template-columns: none
            }

            .faq-questions__item {
                height: 70px;
                &.open {
                    height: auto;
                }

                @include media-breakpoint-down(md) {
                    height: auto;
                    border-radius: 10px;
                    box-shadow: 0px 2px 8px 0px rgba(33, 69, 133, 0.20);
                    padding: 20px;

                }
            }
        }
    }

    .wl_form-section {
        display: flex;
        flex-direction: column;
        margin: 110px 0;
        gap: 25px;

        @include media-breakpoint-down(md) {
            margin: 25px 0 50px;
        }

        .wl_form-title {
            text-align: center;
        }

        .wl_form-form {
            box-shadow: none;
            background: none;

            .contact__form--wrap {
                gap: 5px;
            }
        }
    }

    .btm-section {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("./images/btm-background.png");
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;

            @include media-breakpoint-down(md) {
                background-size: 115% 100%;
            }
        }
    }
}
