@import "../../stylesheets/global.scss";

.faq-questions__item {
    display: flex;
    padding: 20px 32px;
    background: #ffffff;
    border: 1px solid rgba(33, 69, 133, 0.15);
    border-radius: 10px;
    margin-bottom: 6px;

    @include media-breakpoint-down(md) {
        border: none;
        border-bottom: 1px solid rgba(33, 69, 133, 0.15);
        border-radius: 0;
        padding: 13px 0;
    }

    &.open {
        .title-block .item-arrow {
            transform: rotate(-135deg);
        }

        .text-block {
            opacity: 1;
            height: auto;
            padding-top: 20px;
            margin-top: 20px;
            p {
                display: block;
            }
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            h3 {
                font-weight: 500;
                font-size: 18px;
                line-height: 156%;
                color: $main-blue;
                @include media-breakpoint-down(md) {
                    padding-right: 10px;
                }
            }

            .item-arrow {
                border: solid #134563;
                border-width: 0 2px 2px 0;
                display: flex;
                padding: 5px;
                height: 13px;

                transform: rotate(45deg);
                transition: all 0.5s;

                &.open {
                    transform: rotate(-135deg);
                }
            }
        }

        .text-block {
            opacity: 0;
            height: 0;
            padding-top: 0;
            margin-top: 0;
            border-top: 1px solid #f3f3f4;
            transition: all 0.5s;
            p {
                display: none;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 156%;

                color: rgba(6, 17, 33, 0.7);
            }
        }
    }
}
