@import "../../../../stylesheets/global.scss";
@import "../../../../stylesheets/shimmer.scss";

.component__list-item-shimmer {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid;
    border-color: rgba(43, 48, 122, 0.2);
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 0 24px;

    .list-item__shimmer {
        display: flex;
        flex-direction: row;

        &--pharmacy {
            display: flex;
            flex-direction: column;
            width: 40.5%;
            padding: 24px 20px 24px 0;

            .shimmer-text {
                margin-bottom: 6px;
            }

            @include media-breakpoint-down(md) {
                width: auto;
                flex: 2;
            }
        }

        &--package {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-self: center;
            padding: 24px 20px 24px 0;

            .shimmer-text {
                margin-bottom: 10px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &--content {
            display: flex;
            flex: 2;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                flex: 1;
                flex-direction: column;
                justify-content: center;

                .shimmer-price {
                    margin-bottom: 8px;
                }
            }

            @include media-breakpoint-between(lg, xl) {
                .shimmer-price {
                    margin-right: 5px;
                }
            }
        }
    }
}

.search-prefer__shimmer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
