.component__similar-drug {
    .similar-drug__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title {
            font-size: 16px;
            line-height: 125%;
            color: #6f757d;
            padding-right: 12px;
        }

        .similar-btn {
            background: #ffffff;
            border: 1px solid rgba(156, 161, 166, 0.24);
            border-radius: 6px;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            padding: 6px 12px;
            color: #214585;
        }
    }
}
