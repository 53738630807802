@import "../../stylesheets/global.scss";

.component__rxcard {
    display: flex;
    position: relative;
    z-index: 9;

    &--pattern {
        position: absolute;
        top: -31.5%;
        height: 163%;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url("./images/pattern.svg");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 11;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &--card {
        z-index: 15;
        width: 100%;
        aspect-ratio: 457/257;
    }
}
