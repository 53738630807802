@import "../../../stylesheets/global.scss";

.section__howitworks {
    display: flex;

    h2 {
        text-align: center;
    }
}

.howitworks__content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    &--item {
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 75px;

        .react-aspect-ratio-placeholder {
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 0;
            margin-bottom: 25px;

            .react-aspect-ratio-placeholder {
                height: 250px;
                width: 80%;
                align-self: center;
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 0;
        }

        .howitworks-item {
            padding-top: 25px;
            font-family: $font-family-lato;

            &--count {
                display: flex;
                width: 100%;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                color: $purple;

                &::before {
                    content: "";
                    position: relative;
                    display: flex;
                    width: 20px;
                    height: 2px;
                    background-color: $purple;
                    margin-right: 8px;
                }
            }
            &--title {
                padding-top: 10px;
                color: #061121;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
            }

            &--desc {
                font-size: 14px;
                line-height: 24px;
                color: rgba(6, 17, 33, 0.5);
                padding-right: 35px;

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }

                p.empty {
                    height: 50px;
                }
            }
        }
    }
}
