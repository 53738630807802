@import "../../../stylesheets/global.scss";

.component_press-articles {
    position: relative;

    .embla {
        position: relative;
        overflow: hidden;
        padding: 0 50px;
        z-index: 10;
    }

    .embla__container {
        display: flex;
        user-select: none;
    }

    .component__press-article-item {
        flex: 0 0 33.33%;
        margin-right: 15px;
        margin-left: 15px;


        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            margin-right: 5px;
            margin-left: 5px;    
        }
    }

    .embla__control {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .embla__button {
        outline: 0;
        cursor: pointer;
        background-color: transparent;
        touch-action: manipulation;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        fill: $purple;
        padding: 0;

        &--left {
            left: -30px;
        }

        &--right {
            right: -30px;
        }
    }

    .embla__button-svg {
        width: 100%;
        height: 100%;
    }

    .embla__dots {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        z-index: 9;
    }

    .embla__dot {
        display: flex;
        padding: 0;
        width: 12px;
        height: 12px;
        border-radius: 99px;
        border: 1px solid $purple;
        background: #ffffff;
        margin: 0 6px;

        &--selected {
            background: $purple;
        }
    }
}
