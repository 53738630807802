@import "../../stylesheets/global.scss";

.component__drug-saveup {
    display: flex;
    height: 0;
    opacity: 0;
    align-items: center;
    background: rgba(255, 238, 89, 0.83);
    box-shadow: 0px 4px 20px rgb(33 69 133 / 5%);
    transition: all 0.5s;

    &.show {
        opacity: 1;
        height: 56px;
    }

    .drug-saveup__content {
        display: flex;
        position: relative;
        justify-content: center;
    }

    .drug-saveup__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: $main-blue;
    }

    .drug-saveup__learn-more-btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-left: 12px;

        @include media-breakpoint-down(lg) {
            font-size: 0;

            a::after {
                font-size: 24px;
                top: 14px;
            }
        }

        a {
            position: relative;
            color: $main-blue;
            text-decoration-line: underline;
            vertical-align: middle;

            &::after {
                position: absolute;
                content: "\2192";
                padding-left: 5px;
                top: 0;
                bottom: 0;
                transition: all 0.5s;

                @include media-breakpoint-down(lg) {
                    font-size: 24px;
                    top: 14px;
                }
            }

            &:hover {
                &::after {
                    padding-left: 8px;
                }
            }
        }
    }

    .drug-saveup__close-btn {
        display: block;
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        &::before {
            content: "";

            position: absolute;

            width: 18px;
            height: 2px;
            background-color: $main-blue;

            transform: rotate(45deg);

            transition: 0.1s;
        }

        &::after {
            content: "";

            position: absolute;
            left: 0px;
            width: 18px;
            height: 2px;
            background-color: $main-blue;

            transform: rotate(-45deg);
            transition: 0.1s;
        }
    }
}
