.component__result-map {
    width: 100%;
    height: 820px;
    z-index: 9;

    &--loading {
        position: relative;
        background-image: url("./images/default-map.png");
        background-size: contain;
        background-repeat: no-repeat;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            background-color: rgb(33, 37, 41, 0.7);
        }
    }
}
