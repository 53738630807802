body {
    height: 100%;
    margin: 0;
    padding: 0;

    overflow-x: hidden;

    font-family: $font-family-lato;
    font-size: 16px;
    color: $black;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html.is-locked,
html.is-locked body {
    /* want to fix the height to the window height */
    height: calc(var(--window-inner-height) - 1px) !important;

    /* want to block all overflowing content */
    overflow: hidden !important;

    /* want to exclude padding from the height */
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
    outline: none;
}

ul,
ol {
    margin: 0;
    padding: 0;

    list-style: none;
}
input.form-text {
    width: 100%;
    box-shadow: none;
    padding: 0;
    outline: none;
    background: #ffffff;
    border: 1px solid #d5d6e4;
    border-radius: 6px;
}

.thin-custom-row {
    padding-right: calc(var(--bs-gutter-x) * 8);
    padding-left: calc(var(--bs-gutter-x) * 8);

    @include media-breakpoint-down(xl) {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
}
