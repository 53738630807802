@import "../../stylesheets/global.scss";

.component__contact-form {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
    border-radius: 6px;
    padding: 24px 24px 0;

    &.success-send {
        .contact__form {
            opacity: 0;
        }

        .component__contact-form--success {
            opacity: 1;
            p {
                display: inline;
            }
        }
    }

    &--success {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        z-index: 0;
        transition: all 0.5s;

        p.success {
            display: none;
            padding: 0;
            font-size: 18px;
            font-weight: bold;
            color: $green;
            text-align: center;
        }
    }
    .contact__form {
        display: flex;
        flex-direction: column;
        transition: all 0.5s;
        opacity: 1;
        z-index: 10;

        &--wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 24px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                padding-bottom: 0;
            }
        }

        &--label {
            color: #061121;
            font-size: 14px;
            width: 100%;

            @include media-breakpoint-down(md) {
                text-align: left;
                padding-bottom: 24px;
            }
        }

        &--input {
            background: #ffffff;
            border: 1px solid #d5d6e4;
            border-radius: 6px;
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 8px;
            width: 100%;
            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            &:focus {
                border: 1px solid $main-blue;
            }
        }

        &--textarea {
            display: flex;
            width: 100%;
            height: 120px;
            margin-top: 12px;
            padding: 8px 16px;
            background: #ffffff;
            border: 1px solid #d5d6e4;
            border-radius: 6px;
            outline: none;
            &:focus {
                border: 1px solid $main-blue;
            }
        }

        &--submit {
            width: 150px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &--error {
            display: flex;
            justify-content: center;

            p {
                padding: 0;
                color: $red;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}
