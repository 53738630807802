@import "../../../stylesheets/global.scss";

.page-faq__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;

    @include media-breakpoint-down(md) {
        text-align: center;
        padding-bottom: 68px;
    }

    p.subtitle {
        color: #061121;
        mix-blend-mode: normal;
        opacity: 0.6;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 5em;
        display: flex;
        width: 50%;
        text-align: center;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 24px 18px;
        }
    }

    .component__questionform {
        position: relative;
        padding-top: 32px;

        &.success-send {
            .question__form {
                opacity: 0;
            }

            .component__questionform--success {
                opacity: 1;
                p {
                    display: inline;
                }
            }
        }

        &--success {
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 20%;
            left: 0;
            right: 0;
            z-index: 0;
            transition: all 0.5s;

            p.success {
                display: none;
                padding: 0;
                font-size: 18px;
                font-weight: bold;
                color: $green;
                text-align: center;
            }
        }
    }

    .question__form {
        display: flex;
        flex-direction: column;
        transition: all 0.5s;
        opacity: 1;
        z-index: 10;

        &--wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 32px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                padding-bottom: 0;
            }

            &.parts {
                justify-content: space-between;

                @include media-breakpoint-down(md) {
                    justify-content: center;
                }

                .question__form--label {
                    width: 48%;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
        }

        &--label {
            color: #061121;
            font-size: 14px;
            width: 100%;

            @include media-breakpoint-down(md) {
                text-align: left;
                padding-bottom: 24px;
            }
        }

        &--input {
            background: #ffffff;
            border: 1px solid #d5d6e4;
            border-radius: 6px;
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 8px;
            @include media-breakpoint-down(md) {
                margin-top: 10px;
            }

            &:focus {
                border: 1px solid $main-blue;
            }
        }

        &--textarea {
            display: flex;
            width: 100%;
            height: 120px;
            margin-top: 12px;
            padding: 8px 16px;
            background: #ffffff;
            border: 1px solid #d5d6e4;
            border-radius: 6px;
            outline: none;
            &:focus {
                border: 1px solid $main-blue;
            }
        }

        &--submit {
            width: 150px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &--error {
            display: flex;
            justify-content: center;

            p {
                padding: 0;
                color: $red;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}
