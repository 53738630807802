@import "../../../stylesheets/global.scss";

.section__card {
    display: flex;
    padding-top: 30px;
    padding-bottom: 130px;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .get-a-card-btn {
        width: 100%;
        max-width: 150px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 80px;
        flex-direction: column;
    }

    &--content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-right: 2em;

        p {
            color: rgba(6, 17, 33, 0.5);
            padding: 15px 45px 32px 0;
        }

        @include media-breakpoint-down(md) {
            padding-right: 0;
            text-align: center;

            p {
                padding: 16px 0;
                text-align: justify;
            }

            .get-a-card-btn {
                display: none;
            }
        }
    }

    &--card {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
            .get-a-card-btn {
                display: none;
            }
        }
    }
}
