@import "../../../stylesheets/global.scss";

.component__search-result {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    h2 {
        font-weight: normal;
        font-size: 32px;
        line-height: 125%;
        color: #061121;
        padding-bottom: 32px;

        span.count {
            font-family: $font-family-lato;
            font-weight: 500;
            font-size: 18px;
            line-height: 156%;
            color: $main-blue;
        }
    }

    .search-result__main {
        display: flex;
        position: relative;
        flex-direction: column;
        flex: 2;
        max-width: 60%;
        padding-top: 41px;
        padding-left: 4.4em;
        padding-right: 17px;

        @include media-breakpoint-between(md, xl) {
            padding-left: 2.4em;
        }
    }

    .search-result__map {
        position: sticky;
        top: 0;
        display: flex;
        flex: 1;
        height: 820px;
    }

    .component__sort-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .action-item {
            padding-right: 14px;
            .checkbox {
                vertical-align: middle;
                position: relative;
                display: inline-block;
                width: 16px;
                height: 16px;
                border: 2px solid #ececec;
                border-radius: 10px;
                cursor: pointer;
                transition: all 0.5s;

                &.active {
                    border: 2px solid $purple;
                    &::after {
                        opacity: 1;
                    }
                }

                &:hover {
                    border: 2px solid $purple;
                }

                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    background: $purple;
                    display: block;
                    border-radius: 10px;
                    position: absolute;
                    top: calc(50% - 4px);
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: 0 auto;
                    opacity: 0;
                }
            }
            p {
                display: inline;
                padding-left: 6px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
}

.component__search-list {
    display: flex;
    width: 100%;
    flex-direction: column;

    &--results {
        padding-top: 24px;
    }

    &--not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .search-result__item {
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid;
        border-color: rgba(43, 48, 122, 0.2);
        border-radius: 6px;
        margin-bottom: 8px;
        padding: 0 24px;

        &.is-selected {
            border-color: $purple;
        }

        &.item-prefer:last-child {
            margin-bottom: 16px;
        }

        &.item-prefer {
            height: 72px;
            border-color: rgba(33, 69, 133, 0.5);

            .search-result__item--logo {
                flex: 2;
                justify-content: center;

                @include media-breakpoint-between(md, xl) {
                    max-width: 270px;
                }
            }

            .search-result__item--content {
                flex: 1;
                min-width: 38%; //?
            }
        }

        &--logo {
            display: flex;
            .component__pharmacy-logo > img {
                max-height: 52px;

                &.logo-walgreens {
                    max-height: 40px;
                }
                &.logo-cvs {
                    max-height: 30px;
                }
            }
        }

        &--content {
            display: flex;
            flex: 2;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-between(md, xl) {
                justify-content: flex-end;
            }
            .price {
                font-family: $font-family-lato;
                font-weight: 600;
                font-size: 24px;
                line-height: 83%;
                text-align: center;
                color: $main-blue;

                @include media-breakpoint-between(md, xl) {
                    margin-right: 20px;
                }
            }
        }

        &--pharmacy {
            display: flex;
            flex-direction: column;
            width: 40.5%;
            padding: 24px 20px 24px 0;

            span.pharma-name {
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: #090e1d;
            }
            span.pharma-address {
                font-weight: normal;
                font-size: 14px;
                line-height: 143%;
                color: #061121;

                padding: 8px 0 6px 0;
            }
            span.pharma-hours {
                font-weight: normal;
                font-size: 14px;
                line-height: 143%;
                color: #b4b7bc;
            }
        }

        &--package {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-self: flex-start;
            padding: 24px 20px 24px 0;

            span.title {
                font-size: 16px;
                line-height: 150%;
                color: #090e1d;
            }
            span.pack-name {
                font-size: 14px;
                line-height: 143%;
                color: #061121;
            }
        }

        &--distance {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-self: flex-start;
            padding: 24px 20px 24px 0;

            span.title {
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: #090e1d;
            }

            span.distance {
                font-size: 12px;
                line-height: 143%;
                color: $main-blue;
            }
        }
    }
}
