@import "../../stylesheets/global.scss";

.page-contact-us {
    padding-top: 40px;
    p.subtitle {
        padding-top: 16px;
        width: 60%;
        text-align: center;
        mix-blend-mode: normal;
        opacity: 0.6;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .page-contact-us__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page-contact-us__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 24px;

        &--wrap {
            width: 100%;
            max-width: 540px;
        }
    }

    .page-contact-us--additional {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 61px;

        h2 {
            font-weight: 500;
            font-size: 24px;
            line-height: 83%;
            color: #2b307a;
        }
        p.subtitle {
            width: 100%;
        }

        .additional__items {
            padding-top: 16px;
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                justify-content: center;
            }

            .additional__item {
                display: flex;
                flex-direction: column;
                width: 266px;
                background: #ffffff;
                box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
                border-radius: 6px;
                padding: 16px 32px;
                margin-right: 8px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 11px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &--title {
                    font-size: 16px;
                    line-height: 150%;
                    color: rgba(6, 17, 33, 0.7);
                    mix-blend-mode: normal;
                }
                &--value {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 156%;
                    color: $main-blue;
                    mix-blend-mode: normal;
                }
            }
        }
    }
}
