@import "../../../../stylesheets/animation.scss";
@import "../../../../stylesheets/global.scss";

$ball_size: 10px;

.component__mobile-prescription {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: #f8f9fb;
    padding: 12px 16px;

    .mobile-prescription__current span {
        font-weight: bold;
        font-size: 16px;
        line-height: 187%;
        color: #061121;
    }

    .mobile-prescription__edit {
        button.edit-btn {
            padding: 7px 20px;

            background: #e8edf6;
            color: #214585;
            border: none;
            border-radius: 5px;
        }
    }
}

.prescription-modal {
    .prescription-modal__content {
        display: flex;
        flex-direction: column;
        padding: 32px 0;
        max-height: calc(100vh - 138px);
        overflow-y: auto;

        &--item {
            padding-bottom: 10px;

            .small-title {
                font-family: $font-family-prompt;
                font-weight: 500;
                font-size: 16px;
                line-height: 187%;
                color: #061121;
            }

            .prescription__list {
                display: flex;
                flex-wrap: wrap;
                position: relative;

                &--item {
                    display: flex;
                    background: #ffffff;
                    border: 1px solid rgba(156, 161, 166, 0.24);
                    border-radius: 6px;
                    margin-right: 12px;
                    margin-bottom: 12px;

                    // &:nth-child(2n) {
                    //     margin-right: 0;
                    // }

                    &.active {
                        background: #f3f6f8;
                        border: 1px solid #214585;

                        span {
                            color: #214585;
                        }
                    }

                    span {
                        padding: 6px 12px;
                        font-size: 16px;
                        line-height: 137%;
                        color: #061121;
                    }
                }

                &.packages-list {
                    justify-content: space-between;
                    .prescription__list--item {
                        width: calc(50% - 12px);

                        &:nth-child(2n) {
                            margin-right: 0;
                        }
                    }
                }

                &--loader {
                    display: flex;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: #fff;
                    justify-content: center;
                    align-items: center;

                    .component__spinner--balls {
                        @include loading-ball-pulse();

                        > div {
                            animation-fill-mode: both;

                            width: $ball_size;
                            height: $ball_size;
                            border-radius: 100%;
                            margin: calc($ball_size / 2);
                            background-color: $main-blue;

                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .prescription-modal__bottom {
        position: fixed;
        display: flex;
        width: calc(100% - 32px);
        bottom: 15px;
        left: 0;
        right: 0;
        margin: 0 auto;

        .button {
            width: 100%;
        }
    }
}

.component__packages-custom-input {
    display: flex;
    flex-direction: column;
    .package-input {
        display: block;
        margin-top: 8px;
        padding: 6px 12px;
        max-width: 50%;
        background: #ffffff;
        border: 1px solid #d5d6e4;
        border-radius: 6px;

        &.active {
            border: 1px solid #214585;
            color: #214585;
        }
    }
}
