@import "../../../stylesheets/global.scss";

.section__questions {
    display: flex;
    padding-top: 130px;
    padding-bottom: 160px;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        padding-top: 78px;
        padding-bottom: 80px;
        flex-direction: column;
    }

    &--content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-right: 2em;

        @include media-breakpoint-down(md) {
            padding-right: 0;
            align-items: center;
        }

        p {
            color: rgba(6, 17, 33, 0.5);
            padding: 15px 55px 32px 0;

            a {
                color: $purple;
            }

            @include media-breakpoint-down(md) {
                padding: 22px 0 40px 0;
            }
        }

        .button.purple {
            width: 100%;
            max-width: 150px;
            font-weight: normal;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &--img {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        @include media-breakpoint-up(md) {
            .button.purple {
                display: none;
            }
        }
    }
}
