@import "../../../../stylesheets/global.scss";

.component__mobile-map-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e8edf6;
    border: 1px solid #e8edf6;
    padding: 9px 0;

    &--text {
        display: flex;
        color: $main-blue;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        align-items: center;

        &::before {
            content: "";
            background-image: url("./images/map-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }
}

.mobile-drug-map__modal {
    .modal-content {
        padding: 24px 0 0 !important;
    }

    .modal-title {
        padding: 0 16px;
    }
}
