.component__tableerror {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
        max-width: 250px;
    }

    p {
        padding: 10px 25px;
    }
}
