@import "../../stylesheets/global.scss";

.component__pharmacy_partner_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 3em;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        gap: 1em;
    }
}

.component__pharmacy_partner_list--item {
    display: flex;

    @include media-breakpoint-down(md) {
        flex: 1 25%;

        .img {
            max-width: 85%;
        }
    }
}
