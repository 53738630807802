@import "../../../../stylesheets/global.scss";

.component__ts {
    display: flex;
    padding-top: 30px;

    @include media-breakpoint-down(md) {
        padding-top: 15px;
    }

    &--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
            justify-content: space-between;
        }

        .component__ts-list--title {
            padding-top: 3.5px;
            margin-right: 10px;
            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 156%;
                color: $main-blue;
            }

            @include media-breakpoint-down(md) {
                width: 48%;
                margin-right: 0;
                padding-left: 5px;
            }
        }

        .component__ts-list--item {
            background: rgba(164, 173, 250, 0.15);
            box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
            border-radius: 6px;
            padding: 8px 16px;
            margin-right: 8px;
            margin-bottom: 8px;
            cursor: pointer;

            transition: all 0.5s;

            a {
                color: $main-blue;
            }

            &:hover {
                background: rgba(164, 173, 250, 0.3);
            }

            @include media-breakpoint-down(md) {
                margin-right: 0;
                width: 48%;
                text-align: center;
            }
        }
    }
}
