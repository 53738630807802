@import "../../../stylesheets/global.scss";

.page-search-mobile {
    position: relative;

    .page-search-mobile__content {
        h1 {
            font-size: 30px;
            line-height: 133%;
            text-align: center;
            padding-top: 32px;
        }

        .page-search-mobile__prescription {
            padding-top: 18px;

            .component__similar-drug {
                padding-bottom: 16px;
            }
        }
    }

    .page-search-mobile__description {
        padding-top: 63px;
        h2 {
            text-align: center;
            font-size: 24px;
            line-height: 125%;
        }

        &--content {
            padding-top: 24px;

            .content-item {
                padding-bottom: 25px;
                h4 {
                    font-size: 18px;
                    line-height: 178%;
                    color: #061121;
                    padding-bottom: 5px;
                }

                p {
                    font-size: 16px;
                    line-height: 150%;
                    color: #6f757d;
                }
            }
        }
    }

    .page-search-mobile__result {
        .component__search-list {
            padding: 0 6px;
            .search-result__item {
                border: none;
                border-bottom: 1px solid rgba(33, 69, 133, 0.15);
                border-radius: 0;

                &.item-prefer {
                    height: auto;
                    border: 1px solid $purple;
                    border-radius: 6px;
                    padding: 0 15px;
                }

                &--logo img {
                    max-height: 40px;

                    &.logo-cvs {
                        max-height: 22px;
                    }

                    &.logo-rite_aid {
                        max-height: 60px;
                    }

                    &.logo-publix {
                        max-height: 50px;
                    }
                }

                &--pharmacy {
                    flex: 2;
                    width: auto;
                }

                &--distance {
                    display: none;
                }

                &--content {
                    flex: 1;
                    flex-direction: column;
                    padding: 16px 0 18px 0;
                    min-width: auto;
                    .price {
                        color: #061121;
                        padding-bottom: 8px;
                    }
                }
            }
        }

        .component__search-list--not-found {
            position: relative;
            text-align: center;
        }
    }
}
