@import "../../stylesheets/global.scss";

.component__app-download-btns {
    &--wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    .component__app-download-link {
        display: flex;

        img {
            height: 40px;
            &:last-child {
                height: 39px;
            }
        }
    }
}

.component__app-text-me {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 385px;

    .form-text {
        flex: 2;
        padding: 0 16px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .submit-btn {
        flex: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        @include media-breakpoint-down(md) {
            max-width: 125px;
            padding: 10px 5px;
        }
    }
}
