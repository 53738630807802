@import "../../stylesheets/global.scss";

.section__subscription {
    background: #f4f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px 0;

    &.show-success {
        .section__subscription--form {
            display: none;
        }
        .section__subscription--success {
            display: block;
        }
    }

    @include media-breakpoint-down(md) {
        p {
            padding-top: 4px;
            padding-bottom: 13px;
        }
    }

    h2 {
        font-weight: 400;
        font-size: 32px;
        line-height: 125%;
    }

    h2,
    p {
        text-align: center;
    }

    &--form {
        width: 27%;

        @include media-breakpoint-down(md) {
            padding: 13px 16px;
            width: 100%;
        }
        @include media-breakpoint-between(md, xl) {
            padding: 13px 0;
            width: 50%;
        }
    }

    .subscription__form {
        display: flex;
        flex-direction: row;

        &--input {
            background: #ffffff;
            border: 1px solid #e7e8ea;
            box-sizing: border-box;
            border-radius: 6px 0px 0px 6px;
            padding: 7px 16px;
            width: 100%;
        }

        &--submit {
            font-weight: normal;
            padding: 7px 37px;
            border-radius: 0px 6px 6px 0px;
        }
    }

    &--error {
        opacity: 0;
        color: red;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        padding-top: 5px;
        transition: all 0.25s;

        &.show {
            opacity: 1;
        }
    }

    &--success {
        display: none;
        padding: 32px 0;

        h2 {
            color: $green;
        }
    }
}
