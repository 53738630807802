@import "../../stylesheets/global.scss";

.page-faq {
    padding-top: 40px;

    h1 {
        text-align: center;
    }

    .page-faq__highlights {
        padding: 58px 0;
    }

    .page-faq__questions {
        h2 {
            text-align: center;
        }

        &--wrap {
            padding-top: 40px;
        }

        .faq-questions {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}
