@import "../../stylesheets/global.scss";

.dropdown {
    position: relative;

    display: block;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10px;

    background-color: #f3f3f4;
    border-radius: 20px;

    &::before {
        content: "";
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 1;

        width: 10px;
        height: 10px;

        border-color: #090e1d;
        border-right: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(45deg);

        transition: transform 0.2s, border-radius 0.5s;
    }

    &--active {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        &::before {
            transform: rotate(-135deg);
        }
    }

    &:last-child {
        margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
        display: inline-block;
        width: auto;
        margin-right: 20px;
        margin-bottom: 0;

        vertical-align: top;
    }
}

.dropdown__list {
    position: absolute;
    background-color: #f3f3f4;
    top: 100%;
    left: 0;
    right: 0;

    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.dropdown__list--active {
    max-height: 1000px;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    opacity: 1;
    z-index: 1;
}

.dropdown__title {
    padding: 8px 16px;

    font-family: $font-family-lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    /* Text/Dark */

    color: #061121;
    cursor: pointer;
    opacity: 0.6;

    &--active {
        opacity: 1;
    }

    &:hover {
        opacity: 0.8;
    }
}

.dropdown__list-item {
    padding: 11px 48px 11px 20px;
    font-weight: 700;
    font-size: 12px;

    cursor: pointer;
    opacity: 0.6;

    &:hover {
        opacity: 0.8;
    }
}

.dropdown__list-item--active {
    opacity: 1;
}

.dropdown__list-item:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.dropdown__toggle {
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #d5d6e4;
    opacity: 1;
}
