@import "../../stylesheets/global.scss";

// DESKTOP
.component__navigation {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 9;

    &--menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &--menu-item {
        display: flex;
        padding-right: 2.4em;

        &:last-child {
            padding-right: 0;
        }
    }
}

.navigation__link {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
}

// HAMBURGER

.component__hamburger {
    width: 23px;
    height: 14px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    span {
        position: absolute;
        right: 0;

        display: block;
        width: 23px;
        height: 2px;

        background: $white;
        border-radius: 1px;
        transform: rotate(0deg);
        opacity: 1;

        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 6px;
            width: 15px;
        }

        &:nth-child(3) {
            top: 12px;
            width: 9px;
        }
    }

    &--open span {
        &:nth-child(1) {
            top: 6px;
            transform: rotate(135deg);
        }

        &:nth-child(2) {
            display: none;
        }

        &:nth-child(3) {
            top: 6px;
            width: 23px;
            transform: rotate(-135deg);
        }
    }
}

.component__navigation-mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $menu;
    animation: appearance $transition;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;

    background: $main-gradient;
    color: $white;

    // @media (min-width: $dt) {
    //     display: none;
    // }

    &--list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &--menu-item {
        display: block;
        text-align: center;

        &:not(:first-of-type) {
            margin-top: 25px;
        }

        .navigation__link {
            font-size: 18px;
            font-weight: 600;
            // letter-spacing: 0.35px;
        }
    }

    &--disclaimer {
        position: absolute;
        bottom: 15px;
        font-size: 14px;
    }
}

@keyframes appearance {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
