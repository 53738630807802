@import "../../stylesheets/global.scss";

.page-about-us {
    padding-top: 40px;

    * > div.centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    p.subtitle {
        padding-top: 16px;
        width: 75%;
        text-align: center;
        mix-blend-mode: normal;
        opacity: 0.6;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .page-about-us__title {
        padding-bottom: 40px;
    }

    .page-about-us__partners {
        padding-top: 100px;
        padding-bottom: 100px;

        .component__pharmacy_partner_list {
            padding-top: 60px;
            flex-wrap: nowrap;
            width: 70%;

            @include media-breakpoint-down(xl) {
                width: 100%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                flex-wrap: wrap;
            }

            &--item {
                display: flex;
                justify-content: center;
                max-width: 33.33%;

                @include media-breakpoint-up(md) {
                    flex: 1 auto;
                }

                & > div {
                    width: 70%;
                }
            }
        }
    }

    .page-about-us__our-team {
        padding-bottom: 140px;

        @include media-breakpoint-down(md) {
            padding-bottom: 74px;
        }

        .our-team__wrap {
            padding-top: 24px;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .our-team__list {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        .our-team__item {
            display: flex;
            align-items: center;
            padding: 20px 40px;
            background: #ffffff;
            box-shadow: 0px 4px 20px rgb(33 69 133 / 5%);
            border: 1px solid rgba(243, 243, 244, 0.15);
            border-radius: 6px;
            margin-right: 7px;

            @include media-breakpoint-down(md) {
                margin-right: 0;
                box-shadow: none;
                border: none;
                border-radius: 0;
                padding: 16px 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &--content {
                padding-left: 24px;

                h4.name {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 156%;
                    color: #061121;
                }

                p.position {
                    font-size: 14px;
                    line-height: 143%;
                    color: rgba(6, 17, 33, 0.7);
                }
            }
        }
    }

    .page-about-us__our-charityrx {
        padding-bottom: 100px;
        .our-charityrx__img-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 60px;

            @include media-breakpoint-down(md) {
                padding-top: 32px;
                flex-direction: column;
                justify-content: center;
            }

            &--item {
                display: flex;
                margin-right: 30px;

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    margin-bottom: 24px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
