@import "../../stylesheets/global.scss";

.page-partners {
    padding-top: 56px;

    .page-partners__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        p.subtitle {
            width: 50%;
            text-align: center;
            color: #061121;
            padding-top: 12px;
            mix-blend-mode: normal;
            opacity: 0.6;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &--list {
            padding: 46px 0 53px 0;
            @include media-breakpoint-down(md) {
                padding: 30px 0;
            }
        }

        &--table {
            max-width: 500px;
            margin: 0 auto;
            padding: 0 20px;

            @include media-breakpoint-up(md) {
                max-width: 1260px;
                padding: 0 60px 40px;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 170px 40px;
            }

            h2 {
                text-align: center;
                padding-bottom: 50px;
            }
        }
    }
}
