@import "../../stylesheets/global.scss";

.rxcard__actions {
    width: 100%;
    max-width: 380px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    gap: 6px; // remove it

    @include media-breakpoint-down(md) {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
    }

    .rxcard__actions-item {
        display: flex;
        width: calc(50% - 6px);

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: 0;
            margin-right: 0 !important;
            margin-bottom: 6px;
        }

        &.download {
            width: 100%;
        }

        &:not(.download) {
            margin-left: 0;
        }

        &:nth-child(2n) {
            // margin-right: 6px;
        }

        .button {
            width: 100%;
            position: relative;
            margin-bottom: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:not(.download-btn) {
                padding-top: 8px;
                padding-bottom: 8px;
                justify-content: flex-end;

                @include media-breakpoint-down(md) {
                    justify-content: center;
                }
            }

            &:not(.download-btn)::before {
                position: absolute;
                left: 17px;

                @include media-breakpoint-down(md) {
                    position: relative;
                    left: initial;
                }
            }

            &::before {
                content: "";
                display: flex;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 8px;
            }
        }
    }

    .download-btn {
        font-size: 18px;
        line-height: 28px;
        padding-top: 6px;
        padding-bottom: 6px;
        &::before {
            background: url("./images/download.svg");
            width: 24px;
            height: 24px;
        }
    }

    .via-sms::before {
        background: url("./images/sms.svg");
        width: 20px;
        height: 18px;
    }
    .via-mail::before {
        background: url("./images/mail.svg");
        width: 22px;
        height: 15px;
    }
    .via-qr::before {
        background: url("./images/qr.svg");
        width: 20px;
        height: 20px;
    }
    .print::before {
        background: url("./images/print.svg");
        width: 22px;
        height: 22px;
    }
}
