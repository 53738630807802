@import "../../stylesheets/global.scss";

.component__socialicon {
    display: flex;

    .component__socialicon-link {
        width: 25px;
    }

    .icon {
        fill: rgba(6, 17, 33, 0.8);
    }
}
