@import "../../../stylesheets/global.scss";

.component__package-dropdown {
    position: relative;
    display: block;

    &--disabled {
        pointer-events: none;

        .component__package-dropdown--current {
            background: #f3f3f4;

            span.value {
                opacity: 0.5;
            }
        }

        .open-icon {
            &::before,
            &::after {
                opacity: 0.2;
            }
        }
    }

    &--current {
        position: relative;
        background: #ffffff;
        border: 1px solid #f3f3f4;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
            border: 1px solid rgba(156, 161, 166, 0.5);
        }

        span.value {
            display: block;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #061121;

            padding: 8px 30px 8px 16px;
            white-space: nowrap;
        }

        .open-icon {
            position: absolute;
            top: 20px;
            right: 25px;

            &::before {
                content: "";

                position: absolute;

                width: 8px;
                height: 2px;
                background-color: $black;

                transform: rotate(45deg);

                transition: 0.1s;
            }

            &::after {
                content: "";

                position: absolute;
                left: 5px;
                width: 8px;
                height: 2px;
                background-color: $black;

                transform: rotate(-45deg);
                transition: 0.1s;
            }
        }
    }

    &--menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 110%;

        left: -10000px;

        box-sizing: border-box;
        width: 100%;
        max-height: 0;
        // padding: 20px 30px;
        overflow: hidden;

        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 8px 40px 0 rgba(0, 36, 216, 0.1);
        opacity: 0;

        transition: max-height 0.5s linear;

        z-index: 1;

        &.open {
            max-height: 1000px;
            left: 0;
            right: 0;
            opacity: 1;
            z-index: 999;
        }
    }

    .package-dropdown__list {
        max-height: 185px;
        overflow-y: auto;
        margin-right: 3px;
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f3f3f4;
            border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #b4b7bc;
        }

        &--item {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #061121;
            padding: 11px 16px;
            background-color: #fff;
            cursor: pointer;

            &:hover {
                background-color: #f3f3f4;
            }
        }
    }

    .component__package-custom-qty {
        z-index: 99999;
        display: flex;
        .package-custom-qty {
            display: block;
            padding: 17px 16px 16px;
            z-index: 99999;

            p {
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: #061121;
            }
        }

        .package-custom-qty__form {
            display: flex;
            padding-top: 6px;
            z-index: 99999;

            .form-text {
                padding: 0 5px;
                z-index: 99999;
            }

            .button {
                margin-left: 4px;
                font-size: 16px;
                border-radius: 6px;
                padding: 8px 12px;
            }
        }
    }
}
