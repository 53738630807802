@import "../../../stylesheets/global.scss";

.section__app {
    display: flex;
    padding-top: 96px;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        padding-top: 70px;
        padding-bottom: 80px;
        flex-direction: column;
    }

    &--img {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        max-width: 480px;

        @include media-breakpoint-down(md) {
            padding-bottom: 25px;
            width: 100%;
            max-width: 100%;

            img {
                width: 100%;
            }
        }
    }

    &--content {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: 5rem;

        p {
            color: rgba(6, 17, 33, 0.5);
            padding: 15px 0 32px 0;
        }

        @include media-breakpoint-down(md) {
            padding-left: 0;
            text-align: center;

            p {
                padding: 16px 0;
                text-align: justify;
            }

            .get-a-card-btn {
                display: none;
            }

            .component__app-download-btns--wrap {
                justify-content: center;
            }
        }
    }

    &--content-btn {
        margin-top: 25px;
    }
}
