@import "../../stylesheets/global.scss";

.location-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // height: calc(100vh - 80px);

    .location-modal__content {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
    }

    .location-modal__bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        padding-top: 80px;

        .button {
            margin-bottom: 8px;
            width: 100%;
        }

        .set-location {
            padding: 8px 0;
            background: #fff;
            border: 1px solid $main-blue;
            color: $main-blue;
        }
    }

    .location-input {
        margin-bottom: 18px;
        label {
            color: #2b307a;
        }

        .form-text {
            margin-top: 8px;
            padding: 8px 16px;
            font-size: 16px;
            background: #ffffff;
            border: 1px solid #d5d6e4;
            border-radius: 6px;
        }
    }

    .location-modal__spinner {
        background-color: rgb(255, 255, 255, 0.5);
    }
}
