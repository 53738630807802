@import "../../../stylesheets/variables.scss";

.component__searchbar.small .component__searchresult {
    padding: 10px 15px;

    .searchresult__list {
        padding-right: 0;
    }

    p {
        font-size: 14px;
    }
}

.component__searchbar.full .component__searchresult {
    padding: 12px 24px 20px 24px;
}

.component__searchresult {
    display: flex;
    width: 100%;
    z-index: 99;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 8px 20px rgba(23, 45, 156, 0.06);

    &--notfound {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        p {
            color: #6f757d;
            font-size: 16px;
        }
    }

    &--list {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }
}

.searchresult__list {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    max-height: 257px;
    overflow-y: auto;
    padding-right: 22px;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #f3f3f4;
    }

    &--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 0 6px 3px;
        border-bottom: 1px solid rgba(228, 228, 228, 0.85);
        cursor: pointer;
        transition: all 0.3s;
        // &:first-child {
        //     padding-top: 0;
        // }

        &:hover {
            border-radius: 3px;
            background-color: #f7f7ff;
        }
    }

    .searchresult__item--text {
        font-family: $font-family-lato;
        .title {
            font-size: 16px;
            line-height: 150%;
            color: #10133c;
            padding-right: 8px;
        }

        .type {
            font-size: 14px;
            line-height: 143%;
            color: rgba(16, 19, 60, 0.28);
            text-transform: capitalize;
        }
    }

    .searchresult__item--thebest {
        display: flex;

        span {
            color: $purple;
            background: rgba(146, 31, 168, 0.18);
            border-radius: 6px;
            font-size: 14px;
            padding: 4px 16px;
        }
    }
}
