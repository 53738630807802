@import "../../stylesheets/global.scss";

footer.footer {
    background-color: #fafcff;

    &.card-502circle {
        background-color: #231e20;
        p {
            color: #ffffff;
        }

        a.link {
            color: #fff;

            &:hover {
                color: #b0aeae;

                &::after {
                    background: #b0aeae;
                }
            }
        }

        svg.icon {
            fill: #ffffff;
        }
    }
    &.card-alliance412 {
        background-color: #2b368c;
        p {
            color: #ffffff;
        }

        a.link {
            color: #fff;

            &:hover {
                color: #b0aeae;

                &::after {
                    background: #b0aeae;
                }
            }
        }

        svg.icon {
            fill: #ffffff;
        }
    }
    &.card-garnettrust {
        background-color: #731111;

        p {
            color: #ffffff;
        }

        a.link {
            color: #fff;

            &:hover {
                color: #b0aeae;

                &::after {
                    background: #b0aeae;
                }
            }
        }

        svg.icon {
            fill: #ffffff;
        }
    }
}

.footer__container {
    display: flex;
    position: relative;
    align-items: flex-start;
    min-width: 280px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 32px 0;
    flex-direction: row;

    &--block-content,
    &--block-nav {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;

        &--block-content {
            order: 2;
            align-items: center;
            padding: 25px 16px 0 16px;
        }
        &--block-nav {
            order: 1;
        }
    }

    @include media-breakpoint-between(md, xl) {
        padding: 32px 22px;
        &--block-content {
            flex: 2;
        }
    }
}

.footer__block-content {
    &--logo {
        padding-bottom: 24px;
    }

    &--disclamer {
        font-family: $font-family-lato;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #b4b7bc;
        padding-bottom: 14px;
    }

    &--bottom {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        .bottom-links {
            ul {
                display: flex;

                li {
                    padding-right: 40px;
                }

                a {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(6, 17, 33, 0.75);
                    transition: color 0.4s;
                    &:hover {
                        color: $light-purple;
                    }
                }
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        .bottom-copyright p {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #b4b7bc;
        }
    }
}

.footer__container--block-nav {
    padding-top: calc(53px + 24px);
    @include media-breakpoint-down(xl) {
        padding-top: 0;
    }
    .just-for-mobile {
        display: none;
        padding-top: 16px;
        text-align: center;
        li {
            padding-bottom: 16px;
        }
        li a {
            color: rgba(6, 17, 33, 0.8);
            transition: color 0.4s;

            &:hover {
                color: $light-purple;
            }
        }
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .component__navigation {
        justify-content: flex-start;
        padding-left: 30%;
        @include media-breakpoint-down(xl) {
            justify-content: center;
            padding-left: 0;
        }
        @include media-breakpoint-between(md, xl) {
            padding-top: 26%;
        }

        &--menu {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(xl) {
                display: flex;
                flex-direction: column;
            }
        }
        &--menu-item {
            // flex: 1 1 30%;
            // padding-bottom: 16px;

            .navigation__link {
                color: rgba(6, 17, 33, 0.8);
                &:hover {
                    color: $main-blue;

                    &::after {
                        background: $main-blue;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                padding: 0;
                // padding-top: 16px;
                flex: 1;
            }

            @include media-breakpoint-between(md, xl) {
                flex: 1;
                padding-bottom: 5px;
                // padding-right: 0;
            }
        }
    }

    .footer__container--block-nav-btns {
        padding-left: 30%;
        margin-top: 30px;

        &.hide-on-mobile {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        @include media-breakpoint-down(md) {
            padding-left: 0;
            margin: 30px 0;
        }
    }

    .footer__container--block-nav-btns {
        padding-left: 30%;
        margin-top: 15px;

        @include media-breakpoint-down(md) {
            padding-left: 0;
            margin: 30px 0;
        }
    }
}

.component__footer-social-list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 14px;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }

    .footer__social-list {
        display: flex;
        flex-direction: row;
        &--item {
            margin-right: 10px;
        }
    }
}
