@import "../../stylesheets/global.scss";

.component__rxcard {
    display: flex;
    position: relative;
    z-index: 9;

    &--card {
        z-index: 15;
        width: 100%;
        aspect-ratio: 457/257;
    }

    &--card-radius {
        border-radius: 20px;
    }
}

.component__rxcard-cards-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
}