@import "../../../stylesheets/global.scss";

.component__pharmacies-table {
    padding: 0 10px 57px;
}

.pharmacies-table__list {
    @include media-breakpoint-up(md) {
        display: grid;
        grid-auto-flow: column;
        // grid-gap: 0 20px;

        margin-bottom: 20px;
        padding-bottom: 25px;
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 45px;
    }
}

.letter-pagination__item--selected {
    color: $white;

    background-color: $navy-blue;
}

.component__pharmacies-table .dropdown {
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.pharmacies-table .dropdown__list-item {
    font-size: 12px;
    font-weight: $bold;
}

.pharmacies-table__letter-pagination {
    display: none;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
    }
}

.letter-pagination__item {
    margin-right: 40px;

    font-size: 16px;
    font-weight: $medium;
    letter-spacing: 2px;
    text-align: center;

    cursor: pointer;

    border-radius: 17px;

    @include media-breakpoint-down(md) {
        margin-right: 30px;
    }
}

.letter-pagination__item--active {
    margin-right: 28px;
    padding: 2px 10px;

    color: $white;

    border-radius: 15px;
    background-color: #2272e8;
}

.pharmacies-table__item {
    margin-bottom: 10px;

    font-size: 14px;
    line-height: 28px;

    opacity: 0.7;

    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}

.pharmacies-table__item--first-letter {
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: $bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: $white;

    background-color: $navy-blue;
    border-radius: 50%;
    opacity: 1;

    @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
    }
}
