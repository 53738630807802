@import "../../../../stylesheets/global.scss";

.component__mobile-result-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 10px 0;

    .component__mobile-filters-btn {
        display: flex;
        flex: 1;
        justify-content: center;
        border-radius: 5px 5px 0px 0px;
        padding: 15px 0;
        border-bottom: 1px solid #e2eaf3;
        transition: all 0.5s;

        &.active {
            background: rgba(243, 245, 248, 0.6);
            color: $main-blue;
            border-bottom: 2px solid $main-blue;
        }
    }
}
