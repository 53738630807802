@import "../../stylesheets/global.scss";

.component__searchbar {
    display: flex;
    width: 100%;
    position: relative;

    &.small {
        height: 40px;
        justify-content: center;
        align-items: center;

        .component__searchbar--wrap {
            width: 100%;
        }

        .component__searchbar--result {
            top: calc(40px + 5px);
        }
    }

    &.full {
        max-height: 50px;
        .component__searchbar--wrap {
            width: 100%;
        }

        .component__searchbar--result {
            top: calc(50px + 15px);
        }
    }
}

.component__searchbar.full {
    .searchbar__form {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin: 0 auto;
        margin-bottom: 60px;
        max-width: 840px;

        @include media-breakpoint-down(md) {
            &.with-lens::before {
                display: none;
            }
        }

        &.with-lens::before {
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
            left: 25px;
            opacity: 0.5;
        }

        &.with-clear .searchbar__form--clear {
            right: calc(150px + 1em);

            @include media-breakpoint-down(md) {
                right: calc(51px + 1em);
            }
        }

        &--input {
            background-color: #fff;
            border: 1px solid rgba(43, 48, 122, 0.15);
            border-radius: 6px 0 0 6px;
            border-right-width: 0;
            box-shadow: 0 8px 20px rgba(23, 45, 156, 0.06);
            font-size: 18px;
            padding: 12px 15px 12px 50px;

            &::placeholder {
                color: #061121;
                opacity: 0.3;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                padding: 14px 12px;
            }
        }

        &--searchbtn {
            display: flex;
            max-width: 150px;
            border-radius: 0px 6px 6px 0px;
            padding: 0 3.9em;
            justify-content: center;
            align-items: center;
            max-width: 150px;

            @include media-breakpoint-down(md) {
                max-width: none;
                padding: 14px;

                span {
                    display: none;
                }

                &::before {
                    display: flex;
                    content: "";
                    background-image: url("./images/search-white.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 23px;
                    height: 23px;
                }
            }
        }
    }
}

.component__searchbar.small {
    .searchbar__form {
        position: relative;
        display: flex;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: calc(50% - 7px);
            left: 14.5px;
            background-image: url("./images/search.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 15px;
            height: 15px;
        }

        &--input {
            padding: 8px 0;
            padding-right: 30px;
            padding-left: calc(14.5px + 15px + 9px);
            background: #fff;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            border-radius: 10px 6px 6px 10px;
            border: none;

            &::placeholder {
                color: #979797;
            }
        }
    }
}

.searchbar__form.with-clear {
    .searchbar__form--clear {
        opacity: 0;
        display: block;
        position: absolute;
        top: calc(50% - 6px + 2.5%);
        right: 12px;
        background-image: url("./images/cancel.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 12px;
        height: 12px;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
            transform: scale(1.2);
        }

        &.show {
            opacity: 1;
        }
    }
}

.searchbar__form.with-lens {
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - 7.5px);
        left: 7.5px;
        background-image: url("./images/search.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 15px;
        height: 15px;
    }
}

.component__searchbar .component__searchbar--result {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}
