@import "../../../stylesheets/global.scss";

.modal__qr-code {
    .qr-code__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 25px;

        h3 {
            margin-top: 15px;
            font-weight: 600;
            font-size: 24px;
            line-height: 83%;
            color: #061121;
        }
    }

    .qr-code__bottom .button {
        width: 75%;
    }
}

.modal__sms {
    .modal-small__body {
        &::before {
            content: "";
            display: flex;
            align-self: center;
            position: absolute;
            background: url("../images/sms-color.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 66px;
            height: 60px;
            margin-top: 4%;
        }
    }
}

.modal__email {
    .modal-small__body {
        &::before {
            content: "";
            display: flex;
            align-self: center;
            position: absolute;
            background: url("../images/mail-color.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 76px;
            height: 54px;
            margin-top: 4%;
        }
    }
}

.text__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 14%;

    &--form {
        padding-top: 16px;
        width: 90%;

        .input {
            padding: 8px 16px;
        }

        .notice-error {
            opacity: 0;
            text-align: left;
            color: $red;
            font-size: 12px;
            transition: all 0.5s;
        }

        &.error {
            input {
                border-color: $red;
                color: $red;
            }
            p.notice-error {
                opacity: 1;
            }
        }
    }

    .text__content-agree {
        display: flex;
        width: 90%;
        margin-top: 5px;
    }
    .text__content-agree-label {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 8px;
        justify-content: flex-start;
        width: 90%;
        cursor: pointer;
    }

    .text__content-agree-input {
    }

    .text__content-agree-desc {
        font-size: 14px;
        color: #061121;
        mix-blend-mode: normal;
        opacity: 0.8;
    }
}

.text__bottom {
    justify-content: space-between;

    .button {
        margin-right: 6px;
        width: calc(50% - 6px);

        &:last-child {
            margin-right: 0;
        }
    }

    .button.cancel-btn {
        background: #fff;
        border-color: $main-blue;
        color: $main-blue;
        transition: all 0.25s;
        &:hover {
            background: $main-blue;
            color: #fff;
        }
    }
}

.text__bottom-notice {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    .text__bottom-notice-text {
        font-size: 12px;
        color: #061121;
    }
}
