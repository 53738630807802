@import "../../../../stylesheets/global.scss";

.component__mobile-location {
    padding: 14px 16px;
    border-top: 1px solid #f3f6f8;

    &--block {
        position: relative;
        padding-left: calc(12px + 16px);
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $main-blue;

        &::before {
            width: 12px;
            height: 18px;
            content: "";
            display: block;
            position: absolute;
            top: calc(50% - 9px);
            bottom: 0;
            left: 0;
            background: url("./images/location.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
