@import "../../stylesheets/global.scss";

.page-notfound {
    padding: 40px 0;

    .page-notfound__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--content {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            img {
                max-width: 375px;
            }

            h1,
            p {
                text-align: center;
            }

            h1 {
                font-weight: 500;
                font-size: 32px;
                line-height: 125%;
                color: $main-blue;
            }

            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 156%;
                color: rgba(6, 17, 33, 0.7);
                padding: 12px 2em 32px;
            }
        }
    }
}
