@import "../../../stylesheets/global.scss";

.component__drugcard {
    padding: 40px 0;

    &--content {
        h1 {
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 125%;
        }
    }

    &--similar {
        padding-top: 15px;
    }

    .component__drugdescription {
        display: flex;
        flex-wrap: wrap;

        &--main {
            display: flex;
            flex-direction: column;
            flex: 3;
            padding-right: 50px;
        }

        &--sidebar {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-height: 160px;
            position: relative;
            overflow-y: hidden;

            transition: all 0.8s ease-in-out;

            &.expand {
                // max-height: 999px;
                max-height: 100%;
            }
        }

        .drugdescription__content {
            max-height: 130px;
            position: relative;
            overflow-y: hidden;

            transition: all 0.8s ease-in-out;

            &.expand {
                max-height: 999px;
            }

            .excerpt {
                font-size: 14px;
                font-size: 14px;
                line-height: 143%;
                color: #b4b7bc;
            }

            p.description {
                padding-top: 10px;
                font-size: 16px;
                line-height: 24px;
                color: #444444;
            }

            .article-item {
                padding-top: 16px;

                &--title {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 150%;
                    color: #061121;
                    padding-bottom: 8px;
                }

                &--text {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: #444444;
                }
            }
        }

        .drugdescription__sidebar {
            &--img {
                padding-bottom: 25px;
                img {
                    border-radius: 6px;
                    max-height: 260px;
                }
            }

            &--content {
                .article-list-item {
                    &--title {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 150%;
                        color: #061121;
                        padding-bottom: 8px;
                    }

                    &--list {
                        padding-bottom: 24px;
                        li {
                            display: inline;

                            span {
                                font-size: 16px;
                                line-height: 24px;

                                &.color {
                                    color: #921fa8;
                                }
                            }

                            &::after {
                                content: ", ";
                            }

                            &:last-child::after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .button.read-more {
        display: flex;
        flex: 1;
        max-width: 100px;
        border: none;
        background: none;
        padding: 12px 0;
        font-size: 16px;
        font-weight: 400;
        color: $main-blue;
        cursor: pointer;
        transition: all 0.25s;
        max-height: 48px;
        &::after {
            content: "";
            border: solid $main-blue;
            border-width: 0 2px 2px 0;
            display: flex;
            padding: 4px;
            margin-left: 12px;
            align-self: center;

            transform: rotate(45deg);
            transition: all 0.5s;
        }

        &.expanded::after {
            transform: rotate(-135deg);
            margin-top: 6px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
