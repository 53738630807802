@import "../../../../stylesheets/animation.scss";
@import "../../../../stylesheets/shimmer.scss";

.component__drug-crad-shimmer {
    .drug-card__shimmer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &--content {
            display: flex;
            flex-direction: column;
            flex: 3;
            padding-right: 50px;

            .shimmer-title {
                margin-bottom: 5px;
            }
        }

        &--img {
            display: flex;
            margin-top: 32px;
            flex: 1;

            .shimmer-img {
                height: 150px;
            }
        }

        .drug-card__shimmer--desc {
            margin-top: 16px;

            .shimmer-text {
                margin: 8px 0;
            }
        }
    }
}
