@import "../../../stylesheets/global.scss";

.component__prescription {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;

    &--controls {
        display: flex;
        flex-direction: row;
        flex: 3;
        padding-right: 50px;
    }

    &--actions {
        display: flex;
        flex-direction: column;
        flex: 1;

        .button {
            width: 100%;
        }
    }

    .prescription__control {
        display: flex;
        flex: 1;
        padding-right: 10px;

        &:last-child {
            padding-right: 0;
        }

        &--select {
            width: 100%;

            &.prescription-select--is-disabled {
                .prescription-select__control {
                    background: #f3f3f4;
                }
                .prescription-select__indicators {
                    .prescription-select__indicator {
                        opacity: 0.2;
                    }
                }
            }

            .prescription-select__control {
                background: #ffffff;
                border: 1px solid #f3f3f4;
                box-sizing: border-box;
                border-radius: 6px;
                cursor: pointer;
                outline: none;
                box-shadow: none;

                &:hover {
                    border-color: rgba(156, 161, 166, 0.5);
                }

                .prescription-select__value-container {
                    padding: 8px 16px;
                }

                .prescription-select__single-value {
                    margin-left: 0;
                    margin-right: 0;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: #061121;
                }

                .prescription-select__indicators {
                    .prescription-select__indicator-separator {
                        display: none;
                    }
                    .prescription-select__indicator {
                        color: #090e1d;
                    }
                }
            }

            .prescription-select__menu {
                margin-top: 4px;
                border-radius: 6px;
                box-shadow: 0px 8px 40px rgba(33, 69, 133, 0.15);
                z-index: 9999;
            }

            .prescription-select__menu-list {
                border-radius: 6px;
                padding-top: 0;
                padding-bottom: 0;
                .prescription-select__option {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: #061121;
                    padding: 11px 16px;
                    background-color: #fff;
                    cursor: pointer;

                    &--is-focused {
                        background: #f3f3f4;
                    }
                }
            }
        }
    }
}
