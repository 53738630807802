@function delay($interval, $count, $index) {
    @return ($index * $interval) - ($interval * $count);
}

@mixin loading-ball-pulse($n: 3, $start: 1) {
    @for $i from $start through $n {
        > div:nth-child(#{$i}) {
            animation: scale
                0.75s
                delay(0.12s, $n, $i)
                infinite
                cubic-bezier(0.2, 0.68, 0.18, 1.08);
        }
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    45% {
        transform: scale(0.1);
        opacity: 0.7;
    }
    80% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes shimmer-full-view {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

@keyframes shimmer-animation {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}
