@import "../../../stylesheets/global.scss";
@import "../../../stylesheets/animation.scss";

$ball_size: 10px;

.section__samplesaving {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;

    h2,
    p {
        text-align: center;
    }

    p {
        color: rgba(6, 17, 33, 0.5);
    }

    &--wrap {
        width: 100%;
        max-width: 1110px;
        background: #fff;
        box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
        border-radius: 10px;
        border: 1px solid #ebecee;
        padding: 32px 40px;
        margin-top: 32px;

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: none;
            overflow-x: hidden;
            box-shadow: none;
            border: none;
            padding: 0;
            margin-top: 0px;
        }
    }

    .samplesaving__table {
        &--header {
            display: grid;
            grid-template-columns: $sample_saving_grid_size;
            justify-items: left;

            & > div {
                font-family: $font-family-lato;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;

                color: #061121;

                mix-blend-mode: normal;
                opacity: 0.4;

                &:nth-child(1) {
                    padding-left: 24px;
                }
                &:nth-child(2) {
                    padding-left: 15px;
                }
                &:nth-child(3) {
                    margin-left: -7px;
                }
                &:nth-child(4) {
                    justify-self: center;
                }
            }

            @include media-breakpoint-down(xl) {
                display: none;
            }
        }

        &--list {
            position: relative;
            display: flex;
            flex-direction: column;
            // overflow-y: scroll;
            overflow-y: auto;
            list-style-type: none;
            height: 487px;
            text-align: left;
            margin-top: 20px;
            padding-right: 1rem;
            scrollbar-color: #b4b7bc #f3f3f4;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f3f3f4;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #b4b7bc;
            }

            @include media-breakpoint-down(xl) {
                flex-direction: row;
                overflow-x: scroll;
                overflow-y: hidden;
                padding-right: 0;
                max-height: none;
                padding-bottom: 1rem;
                height: auto;

                .samplesaving__table--error {
                    margin-left: calc(50% - 237px);
                }
            }

            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }

        &--loader {
            &.component__spinner {
                position: absolute;
                display: flex;
                top: calc($ball_size / 2);
                bottom: 0;
                left: 0;
                right: 0;
                justify-content: center;
                align-items: center;
            }

            .component__spinner--balls {
                @include loading-ball-pulse();

                > div {
                    animation-fill-mode: both;

                    width: $ball_size;
                    height: $ball_size;
                    border-radius: 100%;
                    margin: calc($ball_size / 2);
                    background-color: $main-blue;

                    display: inline-block;
                }
            }
        }

        &--error {
            display: flex;
            justify-content: center;
            height: 100%;
        }
    }

    .samplesaving_table__item {
        display: grid;
        grid-template-columns: $sample_saving_grid_size;
        justify-items: left;

        border: 1px solid rgba(43, 48, 122, 0.2);
        border-radius: 6px;
        padding: 24px;
        padding-right: 19px;
        margin-top: 8px;

        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-down(xl) {
        }

        &--column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            @include media-breakpoint-down(xl) {
                width: 250px;
            }

            &:nth-child(1),
            &:nth-child(2) {
                justify-content: flex-start;
            }

            h3 {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
                color: #090e1d;
                padding-bottom: 8px;
            }
            p {
                font-size: 14px;
                color: #061121;
            }

            &.column-pharamcy p {
                white-space: pre-line;
                text-align: left;

                @include media-breakpoint-down(md) {
                    padding-top: 0;
                    padding-bottom: 10px;
                }
            }

            &.column-package span {
                font-family: $font-family-lato;
                font-size: 16px;
                line-height: 24px;

                color: #090e1d;
            }

            &.column-total {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .total-block {
                    display: flex;
                    width: 100px;
                }

                span.price {
                    font-family: $font-family-lato;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 20px;
                    color: $main-blue;
                }

                .get-a-card-btn {
                    padding: 8px 30px;
                }

                @include media-breakpoint-down(xl) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    .total-block {
                        width: auto;

                        &:first-child {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
    }

    .samplesaving_table-mobile__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #f3f6f8;
        box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
        border-radius: 6px;
        padding: 16px;
        margin-right: 8px;

        .shimmer-wrapper {
            .shimmer {
                margin-bottom: 5px;
            }

            .shimmer-title {
                width: 65%;
            }

            .shimmer-subtitle {
                margin-bottom: 10px;
            }
        }
    }
}
