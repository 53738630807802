@import "./animation.scss";

.shimmer {
    display: block;
    animation: shimmer-animation 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    border-radius: 2px;
}

.shimmer-wrapper {
    width: 100%;
    height: 100%;
    animation: shimmer-full-view 0.5s forwards
        cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.shimmer-title {
    width: 25%;
    height: 32px;
}

.shimmer-subtitle {
    width: 40%;
    height: 16px;
}

.shimmer-text {
    width: 90%;
    height: 12px;

    &:nth-child(2n) {
        width: 86%;
    }
}

.shimmer-img {
    width: 100%;
    max-height: 300px;
    border-radius: 6px;
}

.shimmer-price {
    color: rgba(255, 255, 255, 0.1);
    background-clip: text;
    background-position: 0 0;
    background-color: #e2e2e2;

    font-weight: 600;
    font-size: 24px;
    line-height: 83%;
    text-align: center;
}

.shimmer-button {
    width: 150px;
    height: 40px;
    border-radius: 6px;
}
