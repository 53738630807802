@import "../../stylesheets/global.scss";

.press-logos__embla {
    position: relative;
    overflow: hidden;
    padding: 0 50px;
    z-index: 10;

    .embla__container {
        display: flex;
        user-select: none;
    }
}

.press-logos__item {
    position: relative;

    display: flex;
    width: 100%;

    flex: 0 0 20%;
    margin: 0 12px;

    @include media-breakpoint-down(md) {
        flex: 0 0 33.33%;
        margin: 0 8px;
    }
}
