@import "../../stylesheets/global.scss";

.component-header {
    position: relative;
    z-index: 10;
    width: 100%;
    background-color: $purple;
    color: white;

    &.is-homepage {
        position: absolute;
    }

    &.transparent {
        background: transparent;

        .header__searchbar {
            opacity: 0;
        }

        .header__navigation a.navigation__link {
            color: $main-blue;
        }

        .header__navigation a.link::after {
            background: $main-blue;
        }
    }

    &.transparent:not(.mobile-open) {
        .header__hamburger span {
            background: $main-blue;
        }
    }

    &.floating {
        position: fixed !important;
        width: 100%;
        // top: -50%;
        // left: 50%;
        // transform: translateY(-50%);
        // transform: translateY(-100%);
        z-index: 1100;
        animation: headerAnimate 0.7s;

        // @include media-breakpoint-down(md) {
        //     display: none;
        // }
    }

    &.card-502circle {
        background-color: #231e20;
    }
    &.card-alliance412 {
        background-color: #2b368c;
    }
    &.card-garnettrust {
        background-color: #731111;
    }
}

.header-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 20px 20px;

    @include media-breakpoint-down(lg) {
        padding: 10px 0 10px 0;
        justify-content: space-between;
    }
}

.header__logo {
    display: flex;
    flex: 1;
    max-width: 150px;
    margin-right: 50px;
    z-index: $menu + 1;
}

.header__searchbar {
    display: flex;
    flex: 1;
    justify-content: center;

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.header__navigation {
    display: flex;
    flex: 2;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.header__hamburger {
    display: none;
    @include media-breakpoint-down(lg) {
        position: relative;
        z-index: $menu + 10;
        display: flex;
    }
}

.component-header__hamburger {
    position: relative;

    width: 20px;
    height: 14px;
    margin-left: auto;

    vertical-align: middle;
    text-align: right;

    transform: rotate(0deg);
    cursor: pointer;

    transition: 0.5s ease-in-out;

    @include media-breakpoint-up(xl) {
        display: none;
    }

    @include media-breakpoint-down(md) {
        margin-right: 20px;
    }
}

.component-header__hamburger span {
    position: absolute;
    left: 0;

    display: block;
    width: 20px;
    height: 2px;

    background: $white;
    border-radius: 1px;
    transform: rotate(0deg);
    opacity: 1;

    transition: 0.25s ease-in-out;
}

@keyframes headerAnimate {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
