h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    font-family: $font-family-prompt;
    color: $main-blue;
    font-weight: 600;
    font-size: 44px;
    line-height: 48px;

    @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: 36px;
        font-weight: 500;
    }
}

h1.page-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;
}

h2 {
    font-family: $font-family-prompt;
    color: $main-blue;
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;

    @include media-breakpoint-down(md) {
        font-size: 24px;
    }
}

p.subtitle {
    font-family: $font-family-lato;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 150%;
        padding-top: 10px;
    }
}

p {
    font-family: $font-family-lato;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
}

a {
    position: relative;
    text-decoration: none;
    transition: opacity $transition;
    color: $main-blue;

    &:hover {
        text-decoration: none;
        color: inherit;
        opacity: 0.8;
    }

    &.link {
        &:hover {
            opacity: 1;
            &::after {
                opacity: 1;
            }
        }

        &::after {
            content: "";
            width: 100%;
            display: block;
            opacity: 0;
            height: 1px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.5s;
        }
    }
}

small {
    font-size: 0.6em;
}
