@import "../../../../stylesheets/global.scss";

.component__map-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 38px;
    background-image: url("./images/marker.svg");
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &.is-selected {
        background-image: url("./images/marker-hover.svg");
    }
}

.component__mapmarker-infowindow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 8px 40px rgba(33, 69, 133, 0.15);
    border-radius: 6px;
    padding: 34px 24px 24px 24px;
    z-index: 999;

    .block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    .infowindow__close {
        position: absolute;
        top: 29px;
        right: 29px;

        span.close-icon {
            position: relative;
            display: block;
            background-image: url("../../../../components/modal-window/images/close-icon.svg");
            background-repeat: no-repeat;
            width: 14px;
            height: 14px;
        }
    }

    .infowindow__content {
        flex: 2;
        padding-right: 15px;
        & > span {
            display: block;
        }
        &--name {
            font-family: $font-family-lato;
            font-weight: 700;
            font-size: 16px;
            line-height: 125%;
            color: #090e1d;
            padding-bottom: 6px;
        }
        &--address {
            font-family: $font-family-lato;
            font-weight: 400;
            font-size: 14px;
            line-height: 143%;
            color: #757e8a;
            padding-bottom: 4px;
        }
        &--time {
            font-family: $font-family-lato;
            font-weight: 400;
            font-size: 12px;
            line-height: 167%;
            color: #757e8a;
        }
    }
    .infowindow__price {
        flex: 1;
        // align-content: flex-end;
        max-width: 65px;

        span {
            font-family: $font-family-lato;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
            color: #090e1d;
        }
    }

    .infowindow__btn {
        flex: 1;
        margin-top: 25px;
        width: 100%;

        .button {
            width: 100%;
        }
    }
}
