@import "../../../stylesheets/global.scss";

.section__reviews {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 150px;

    @include media-breakpoint-down(md) {
        padding-bottom: 50px;
    }

    h2,
    p.subtitle {
        text-align: center;
    }

    p.subtitle {
        width: 60%;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6f757d;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &--content {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 32px;

        @include media-breakpoint-down(xl) {
            align-items: flex-start;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .reviews__item {
        display: flex;
        flex: 1 1;
        padding: 0 15px;
        height: 225px;

        @include media-breakpoint-down(xxl) {
            height: auto;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }

        &--wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 24px;
            background: #ffffff;
            border: 1px solid rgba(43, 48, 122, 0.05);
            border-radius: 6px;
            box-shadow: 0px 4px 20px rgba(33, 69, 133, 0.05);
        }

        &--header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @include media-breakpoint-between(md, lg) {
                flex-direction: column;
                align-items: flex-start;
            }

            .review-header-block {
                display: flex;

                img {
                    width: 50px;
                    height: 50px;
                }
            }

            .user-info {
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .city {
                    color: #b4b7bc;
                    font-size: 14px;
                    line-height: 20px;
                }

                .reviewer-name {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: #6f757d;
                }
            }

            .rate {
                padding-top: 5px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .star {
                    display: flex;
                    margin: 0 2px;
                    &::before {
                        content: "\2605";
                        color: #fdc219;
                    }
                }
            }
        }

        &--content {
            display: flex;
            width: 100%;
            padding-top: 19px;
            p {
                font-size: 16px;
                line-height: 150%;
                color: #061121;
            }
        }
    }
}
